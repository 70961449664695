import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EquipmentService } from '../../_services/equipment.service';
import { Equipment } from '../../_models/equipment';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, catchError } from 'rxjs/operators';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { AttendancePlace } from '../../_models/attendance-place';
import { Brand } from '../../_models/brand';
import { BrandService } from '../../_services/brand.service';

@Component({
  selector: 'app-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.css']
})
export class EquipmentDetailComponent implements OnInit {
  detailForm: FormGroup;
  idEquipment = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';
  idAttendancePlace = 0;
  brandList: Brand[];

  formatter = (attendancePlace: AttendancePlace) => attendancePlace.name;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private equipmentService: EquipmentService, private attendancePlaceService: AttendancePlaceService, private brandService: BrandService) { }

  ngOnInit() {
    this.detailForm = this.formBuilder.group({
      equipmentName: ['', Validators.required], model: [''], stopNumber: [null], capacity: [null], numberPassenger: [null], serialNumber: [''], numberPlate: [''],
      hasAttendance: [false], tagCode: [''], status: [''], idExternal: [''], qrCode: [null], attendancePlace: [null, Validators.required], contract: [''], idBrand: [null], idExternalBrand: ['']
    });

    this.activeRoute.params.subscribe(routeParams => {
      this.idEquipment = parseInt(routeParams.id);
      this.idAttendancePlace = parseInt(routeParams.idAttendancePlace);
    });

    this.getBrands();

    if (this.idEquipment > 0) {

      this.detailForm.disable();

      this.equipmentService.getEquipment(this.idEquipment)
        .subscribe(
          data => {
            this.detailForm.setValue({
              equipmentName: data.name, model: data.model, stopNumber: data.stopNumber, capacity: data.capacity, numberPassenger: data.numberPassenger, serialNumber: data.serialNumber, numberPlate: data.numberPlate, idBrand: data.brand ? data.brand.id : null, idExternalBrand: data.brand ? data.brand.idExternal : "",
              hasAttendance: data.hasAttendance, tagCode: data.tagCode, status: data.status, idExternal: data.idExternal, qrCode: data.qrCode, attendancePlace: data.attendancePlace, contract: data.attendancePlace ? data.attendancePlace.contract.contractNumber : ""
            });
          },
          error => {
            this.router.navigate(['equipment']);
          });
    }
    else {
      this.setEditMode();

      if (this.idAttendancePlace) {
        this.getAttendancePlace();
      }
    }
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    var newEquipment = new Equipment();
    newEquipment.id = this.idEquipment;
    newEquipment.idExternal = parseInt(this.detailForm.controls.idExternal.value);
    newEquipment.idAttendancePlace = this.detailForm.controls.attendancePlace.value.id;
    newEquipment.name = this.detailForm.controls.equipmentName.value;
    newEquipment.model = this.detailForm.controls.model.value;
    newEquipment.stopNumber = this.detailForm.controls.stopNumber.value;
    newEquipment.capacity = this.detailForm.controls.capacity.value;
    newEquipment.numberPassenger = this.detailForm.controls.numberPassenger.value;
    newEquipment.serialNumber = this.detailForm.controls.serialNumber.value;
    newEquipment.numberPlate = this.detailForm.controls.numberPlate.value;
    newEquipment.hasAttendance = this.detailForm.controls.hasAttendance.value;
    newEquipment.qrCode = this.detailForm.controls.qrCode.value;
    newEquipment.idBrand = this.detailForm.controls.idBrand.value;

    this.equipmentService.createOrUpdate(this.idEquipment, newEquipment)
      .subscribe(
        data => {
          this.router.navigate(['equipment/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  setContract() {
    if (this.detailForm.controls.attendancePlace.value) {
      this.detailForm.controls.contract.setValue(this.detailForm.controls.attendancePlace.value.contract.contractNumber);
    }
    else {
      this.detailForm.controls.contract.setValue("");
    }
  }

  setBrand() {
    if (this.detailForm.controls.idBrand.value) {
      var brand = this.brandList.filter(el => el.id == this.detailForm.controls.idBrand.value);
      this.detailForm.controls.idExternalBrand.setValue(brand[0].idExternal);
    }
    else {
      this.detailForm.controls.idExternalBrand.setValue("");
    }
  }

  searchAttendancePlaces = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.attendancePlaceService.getAttendancePlacesByName(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  private getAttendancePlace() {
    this.attendancePlaceService.getAttendancePlace(this.idAttendancePlace)
      .subscribe(
        data => {
          this.detailForm.controls.attendancePlace.setValue(data);
          this.setContract();
        },
        error => {
          this.router.navigate(['equipment/list']);
        });
  }

  private getBrands() {
    this.brandService.getBrandList()
      .subscribe(
        data => {
          this.brandList = data;
        },
        error => {
          this.router.navigate(['equipment/list']);
        });
  }
}
