import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private repo: RepositoryService) { }

  getUsers(page: number, userName: string, onlyActives: boolean) {
    let urlParams = '?page=' + page;

    if (userName) {
      urlParams += '&argument=' + userName;
    }

    if (onlyActives) {
      urlParams += '&onlyActives=' + onlyActives;
    }

    return this.repo.getData('users' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<User>) => {
        return paginatedResponse;
      }));
  }

  getUser(id: number) {
    return this.repo.getData('users/' + id)
      .pipe(map((user: User) => {
        return user;
      }));
  }

  createOrUpdateUser(id: number, user: User) {
    if (id && id > 0) {
      return this.repo.put('users/' + id, user);
    }
    else {
      user.id = null;
      return this.repo.post('users', user);
    }
  }

  setPassword(idUser: number, password: string, token: string) {
    return this.repo.post('users/password', { idUser, password, token })
  }

  sendRecoveryEmail(username: string) {
    return this.repo.post('users/password/recovery?username=' + username, null);
  }

  getProfileTypes() {
    return this.repo.getData('users/profileTypes')
      .pipe(map((profileList: any[]) => {
        return profileList;
      }));
  }

  getUsersActive() {
    return this.repo.getData('users/active')
      .pipe(map((userList: User[]) => {
        return userList;
      }));
  }

  deleteUser(id: number) {
    return this.repo.delete('users/' + id);
  }

  setAuthorizationToLogin(id: number, token: string, authorization: boolean, isCancel) {
    return this.repo.post('auth/authorization/login', { idUser: id, token: token, authorization: authorization, isCancel: isCancel });
  }

  checkAuthorization(id: number, token: string) {
    return this.repo.getData('auth/authorization/check/id/' + id + '/token/' + token)
      .pipe(map((authorized: boolean) => {
        return authorized;
      }));;
  }
}
