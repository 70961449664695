import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private repo: RepositoryService) { }

  getCustomerPerRoute(page: number, idRoute: number, contract: number) {
    let urlParams = '?page=' + page;

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    return this.repo.getData('reports/customerPerRoute' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getAttendancePerCustomer(page: number, idAttendancePlace: number, contract: number) {
    let urlParams = '?page=' + page;

    if (idAttendancePlace) {
      urlParams += '&idAttendancePlace=' + idAttendancePlace;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    return this.repo.getData('reports/attendancePerCustomer' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getEquipmentPerRoute(page: number, idRoute: number, hasAttendance: boolean, contract: number) {
    let urlParams = '?page=' + page;

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (hasAttendance) {
      urlParams += '&hasAttendance=' + hasAttendance;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    return this.repo.getData('reports/equipmentPerRoute' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getAttendanceGeneral(page: number, idRoute: number, idAttendancePlace: number, contract: number, attendanceStatus: number, month: number, year: number) {
    let urlParams = '?page=' + page;

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (idAttendancePlace) {
      urlParams += '&idAttendancePlace=' + idAttendancePlace;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    if (attendanceStatus) {
      urlParams += '&attendanceStatus=' + attendanceStatus;
    }

    if (month) {
      urlParams += '&month=' + month;
    }

    if (year) {
      urlParams += '&year=' + year;
    }

    return this.repo.getData('reports/attendanceGeneral' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getCalledPerPlace(page: number, idAttendancePlace: number, fromDate: Date, toDate: Date, status: number) {
    let urlParams = '?page=' + page;

    if (idAttendancePlace) {
      urlParams += '&idAttendancePlace=' + idAttendancePlace;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (status) {
      urlParams += '&status=' + status;
    }

    return this.repo.getData('reports/calledPerPlace' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getCalledPerRouteGeneral(page: number, idRoute: number, fromDate: Date, toDate: Date, status: number) {
    let urlParams = '?page=' + page;

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (status) {
      urlParams += '&status=' + status;
    }

    return this.repo.getData('reports/calledPerRouteGeneral' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getCalledPerRoute(page: number, idRoute: number, fromDate: Date, toDate: Date, status: number) {
    let urlParams = '?page=' + page;

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (status) {
      urlParams += '&status=' + status;
    }

    return this.repo.getData('reports/calledPerRoute' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  getCalledCritical(page: number, fromDate: Date, toDate: Date, quantity: number) {
    let urlParams = '?page=' + page;

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (quantity) {
      urlParams += '&quantity=' + quantity;
    }

    return this.repo.getData('reports/calledCritical' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<any>) => {
        return paginatedResponse;
      }));
  }

  exportCustomerPerRoute(idRoute: number, contract: number) {
    let urlParams = '';

    if (idRoute) {
      urlParams += '?idRoute=' + idRoute;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    return this.repo.getDataFile('reports/customerPerRoute/export' + urlParams);
  }

  exportAttendancePerCustomer(idAttendancePlace: number, contract: number) {
    let urlParams = '';

    if (idAttendancePlace) {
      urlParams += '?idAttendancePlace=' + idAttendancePlace;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    return this.repo.getDataFile('reports/attendancePerCustomer/export' + urlParams);
  }

  exportEquipmentPerRoute(idRoute: number, hasAttendance: boolean, contract: number) {
    let urlParams = '';

    if (idRoute) {
      urlParams += '?idRoute=' + idRoute;
    }

    if (hasAttendance) {
      urlParams += '&hasAttendance=' + hasAttendance;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    return this.repo.getDataFile('reports/equipmentPerRoute/export' + urlParams);
  }

  exportAttendanceGeneral(idRoute: number, idAttendancePlace: number, contract: number, attendanceStatus: number, month: number, year: number) {
    let urlParams = '';

    if (month) {
      urlParams += '?month=' + month;
    }

    if (year) {
      urlParams += '&year=' + year;
    }

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (idAttendancePlace) {
      urlParams += '&idAttendancePlace=' + idAttendancePlace;
    }

    if (contract) {
      urlParams += '&contract=' + contract;
    }

    if (attendanceStatus) {
      urlParams += '&attendanceStatus=' + attendanceStatus;
    }

    return this.repo.getDataFile('reports/attendanceGeneral/export' + urlParams);
  }

  exportCalledPerPlace(idAttendancePlace: number, fromDate: Date, toDate: Date) {
    let urlParams = '';

    if (idAttendancePlace) {
      urlParams += '?idAttendancePlace=' + idAttendancePlace;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    return this.repo.getDataFile('reports/calledPerPlace/export' + urlParams);
  }

  exportCalledPerRouteGeneral(idRoute: number, fromDate: Date, toDate: Date, status: number) {
    let urlParams = '';

    if (idRoute) {
      urlParams += '?idRoute=' + idRoute;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (status) {
      urlParams += '&status=' + status;
    }

    return this.repo.getDataFile('reports/calledPerRouteGeneral/export' + urlParams);
  }

  exportCalledPerRoute(idRoute: number, fromDate: Date, toDate: Date, status: number) {
    let urlParams = '';

    if (idRoute) {
      urlParams += '?idRoute=' + idRoute;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (status) {
      urlParams += '&status=' + status;
    }

    return this.repo.getDataFile('reports/calledPerRoute/export' + urlParams);
  }

  exportCalledCritical(fromDate: Date, toDate: Date, quantity: number) {
    let urlParams = '';

    if (fromDate) {
      urlParams += '?fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (quantity) {
      urlParams += '&quantity=' + quantity;
    }

    return this.repo.getDataFile('reports/calledCritical/export' + urlParams);
  }
}
