import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { PaginatedResponse } from '../_models/paginated-response';
import { Checklist } from '../_models/checklist';
import { map } from 'rxjs/operators';
import { ChecklistCategory } from '../_models/checklist-category';
import { ChecklistItem } from '../_models/checklist-item';
import { ChecklistItemInput } from '../_models/checklist-item-input';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {

  constructor(private repo: RepositoryService) { }

  getChecklists(page: number, argument: string) {
    let urlParams = '?page=' + page;

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('checklists' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Checklist>) => {
        return paginatedResponse;
      }));
  }

  getAllChecklists() {
    return this.repo.getData('checklists/all')
      .pipe(map((checklists: Checklist[]) => {
        return checklists;
      }));
  }

  getChecklist(id: number) {
    return this.repo.getData('checklists/' + id)
      .pipe(map((checklist: Checklist) => {
        return checklist;
      }));
  }

  getChecklistByEquipment(idEquipment: number) {
    return this.repo.getData('checklists/equipment/' + idEquipment)
      .pipe(map((checklist: Checklist) => {
        return checklist;
      }));
  }

  getCategoriesByName(name: string) {
    
    return this.repo.getData('checklistCategories/name/' + name + '/list')
      .pipe(map((checklistCategories: ChecklistCategory[]) => {
        return checklistCategories;
      }));
  }

  getCategoryByName(name: string) {

    return this.repo.getData('checklistCategories/name/' + name)
      .pipe(map((checklistCategory: ChecklistCategory) => {
        return checklistCategory;
      }));
  }

  createOrUpdate(checklist: Checklist) {
    if (checklist.id && checklist.id > 0) {
      return this.repo.put('checklists/' + checklist.id, checklist)
        .pipe(map((checklistResponse: any) => {
          return checklistResponse.data as Checklist;
        }));
    }
    else {
      return this.repo.post('checklists', checklist)
        .pipe(map((checklistResponse: any) => {
          return checklistResponse.data as Checklist
        }));
    }
  }

  createOrUpdateItem(id: number, checklistItemInput: ChecklistItemInput) {

    if (id && id > 0) {
      return this.repo.put('checklistItems/' + id, checklistItemInput)
        .pipe(map((checklistResponse: any) => {
          return checklistResponse.data as ChecklistItem
        }));
    }
    else {
      return this.repo.post('checklistItems', checklistItemInput)
        .pipe(map((checklistResponse: any) => {
          return checklistResponse.data as ChecklistItem
        }));
    }
  }

  deleteItemCategory(idItem: number) {
    return this.repo.delete('checklistItems/' + idItem);
  }

  createCategory(checklistCategory: ChecklistCategory) {
    return this.repo.post('checklistCategories', checklistCategory)
      .pipe(map((category: any) => {
        return category.data as ChecklistCategory
      }));
  }

}
