import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Occurrence } from '../../_models/occurrence';
import { OccurrenceService } from '../../_services/occurrence.service';

@Component({
  selector: 'app-occurrence-list',
  templateUrl: './occurrence-list.component.html',
  styleUrls: ['./occurrence-list.component.css']
})
export class OccurrenceListComponent implements OnInit {

  occurrences: Occurrence[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;

  constructor(private formBuilder: FormBuilder, private occurrenceService: OccurrenceService) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({ argument: [''] });

    this.get(this.page, this.searchForm.controls.argument.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.argument.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.argument.value);
  }

  private get(page: number, argument: string) {
    this.submitted = true;

    this.loading = true;
    this.occurrenceService.getOccurrences(page, argument)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.occurrences = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
