import { Customer } from "./customer";

export class Contract {
    id: number;
    contractNumber: string;
    attendanceFactorName: string;
    attendanceFactor: number;
    customer: Customer;
    status: number;
    statusName: string;
    startDate: Date;
    endDate: Date;
}
