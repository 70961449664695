import { AttendancePlace } from "./attendance-place";
import { Equipment } from "./equipment";
import { Occurrence } from "./occurrence";
import { Solution } from "./solution";
import { User } from "./user";

export class Called {
  id: number;
  calledNumber: string;
  creationDate: Date;
  startDate: Date;
  endDate: Date;
  startDateText: string;
  endDateText: string;
  requesterName: string;
  escortName: string;
  requesterPhone: string;
  informedObservation: string;
  attendanceObservation: string;
  inOperation: boolean;
  pendency: boolean;
  status: number;
  statusName: string;
  idAttendancePlace: number;
  attendancePlace: AttendancePlace;
  idEquipment: number;
  equipment: Equipment;
  idInformedOccurrence: number;
  informedOccurrence: Occurrence;
  idAttendanceOccurrence: number;
  attendanceOccurrence: Occurrence;
  idSolution: number;
  solution: Solution;
  idCreationResponsible: number;
  creationResponsible: User;
  idAttendanceResponsible: number;
  attendanceResponsible: User;
  solutionObservation: string;
  pendencyObservation: string;
  idFinishingResponsible: number;
  finishingResponsible: User;
  thirdPartyName: string;
}
