import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AttendancePlaceFilterModel } from '../../_models/attendance-place-report-filter';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, catchError } from 'rxjs/operators';
import { AttendancePlace } from '../../_models/attendance-place';

@Component({
  selector: 'app-attendance-per-place',
  templateUrl: './attendance-per-place.component.html',
  styleUrls: ['./attendance-per-place.component.css']
})
export class AttendancePerPlaceComponent implements OnInit {
  editForm: FormGroup;
  idAttendancePlace = 0;
  attendancePlace = "";
  submitted = false;
  error = '';
  editMode = false;
  itemError = false;
  attendancePlaceFilterModel = null;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private attendancePlaceService: AttendancePlaceService) {
  }

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      attendancePlace: ['', Validators.required], startMonthYear: ['', Validators.required], endMonthYear: ['']
    });
  }

  ngOnSubmit() {

    this.submitted = true;

    if (this.editForm.invalid) {
      return;
    }

    this.attendancePlaceFilterModel = new AttendancePlaceFilterModel();
    this.attendancePlaceFilterModel.id = this.idAttendancePlace;
    this.attendancePlaceFilterModel.startMonth = this.editForm.controls.startMonthYear.value;
    this.attendancePlaceFilterModel.startYear = this.editForm.controls.startMonthYear.value;
    this.attendancePlaceFilterModel.endMonth = this.editForm.controls.endMonthYear.value;
    this.attendancePlaceFilterModel.endYear = this.editForm.controls.endMonthYear.value;

    /*this.attendancePlaceService.reportByPlace(this.attendancePlaceFilterModel)
      .subscribe(
        data => {
          this.router.navigate(['attendancePlace/report/place' + data.id], data);
        },
        error => {
          this.error = error;
        });*/
  }

  searchAttendancePlaces = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.attendancePlaceService.getAttendancePlacesByName(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  inputFormatAttendanecPlaceValue(value: any) {
    if (value.name) {
      this.idAttendancePlace = value.id;
      return value.name
    }

    this.idAttendancePlace = null;
    return value;
  }

  resultFormatAttendancePlaceValue(value: any) {
    return value.name;
  }
}
