import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';
import { Solution } from '../_models/solution';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  constructor(private repo: RepositoryService) { }

  getSolutions(page: number, argument: string) {
    let urlParams = '?page=' + page;

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('solutions' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Solution>) => {
        return paginatedResponse;
      }));
  }

  getSolution(id: number) {
    return this.repo.getData('solutions/' + id)
      .pipe(map((solution: Solution) => {
        return solution;
      }));
  }

  getSolutionList() {
    return this.repo.getData('solutions/all')
      .pipe(map((solutions: Solution[]) => {
        return solutions;
      }));
  }

  createOrUpdate(id: number, solution: Solution) {
    if (id && id > 0) {
      return this.repo.put('solutions/' + id, solution);
    }
    else {
      solution.id = null;
      return this.repo.post('solutions', solution);
    }
  }
}
