import { Component, OnInit } from '@angular/core';
import { Customer } from '../../_models/customer';
import { CustomerService } from '../../_services/customer.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  customers: Customer[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;

  constructor(private formBuilder: FormBuilder, private customerService: CustomerService) { }

  ngOnInit() {

    this.searchForm = this.formBuilder.group({ customerName: [''] });

    this.getCustomers(this.page, this.searchForm.controls.customerName.value);
  }

  onSubmit() {

    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.getCustomers(this.page, this.searchForm.controls.customerName.value)
  }

  onSelectedPage() {
    this.getCustomers(this.page, this.searchForm.controls.customerName.value)
  }

  private getCustomers(page: number, customerName: string) {
    this.submitted = true;

    this.loading = true;
    this.customerService.getCustomers(page, customerName)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.customers = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
