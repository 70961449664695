import { Component, OnInit } from '@angular/core';
import { Checklist } from '../../_models/checklist';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChecklistService } from '../../_services/checklist.service';

@Component({
  selector: 'app-checklist-list',
  templateUrl: './checklist-list.component.html',
  styleUrls: ['./checklist-list.component.css']
})
export class ChecklistListComponent implements OnInit {

  checklists: Checklist[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;

  constructor(private formBuilder: FormBuilder, private checklistService: ChecklistService) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({ checklistName: [''] });

    this.getChecklists(this.page, this.searchForm.controls.checklistName.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.getChecklists(this.page, this.searchForm.controls.checklistName.value)
  }

  private getChecklists(page: number, checklistName: string) {
    this.submitted = true;

    this.loading = true;
    this.checklistService.getChecklists(page, checklistName)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.checklists = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  onSelectedPage() {
    this.getChecklists(this.page, this.searchForm.controls.checklistName.value)
  }

}
