import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { Customer } from '../_models/customer';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

    constructor(private repo: RepositoryService) { }

    getCustomers(page: number, customerName: string) {
        let urlParams = '?page=' + page;

        if (customerName) {
            urlParams += '&argument=' + customerName;
        }

        return this.repo.getData('customers' + urlParams)
            .pipe(map((paginatedResponse: PaginatedResponse<Customer>) => {
                return paginatedResponse;
            }));
    }

    getCustomer(id: number) {
        return this.repo.getData('customers/' + id)
            .pipe(map((customer: Customer) => {
                return customer;
            }));
    }
  
    createOrUpdate(id: number, customer: Customer) {
        if (id && id > 0) {
            return this.repo.put('customers/' + id, customer);
        }
        else {
            customer.id = null;
            return this.repo.post('customers', customer);
        }
    }
  
    getCustomerTypes() {
        return this.repo.getData('customers/personTypes')
              .pipe(map((customerTypesList: any[]) => {
                  return customerTypesList;
              }));
    }

    getCustomersActives() { 
        return this.repo.getData('customers/actives')
              .pipe(map((customers: Customer[]) => {
                  return customers;
              }));
    }
      
    getByArgument(argument: string) {
      return this.repo.getData('customers/list?argument=' + argument)
            .pipe(map((customers: Customer[]) => {
                return customers;
            }));
    }
}
