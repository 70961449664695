import { Component, OnInit } from '@angular/core';
import { Employee } from '../../_models/employee';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EmployeeService } from '../../_services/employee.service';
import { ModalDefaultService } from '../../modal-default/modal-default.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  employees: Employee[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  deleteId = 0;

  constructor(private formBuilder: FormBuilder, private employeeService: EmployeeService, private modalService: ModalDefaultService) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({ argument: [''] });

    this.getEmployees(this.page, this.searchForm.controls.argument.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.getEmployees(this.page, this.searchForm.controls.argument.value);
  }

  onSelectedPage() {
    this.getEmployees(this.page, this.searchForm.controls.argument.value);
  }

  private getEmployees(page: number, argument: string) {
    this.submitted = true;

    this.loading = true;
    this.employeeService.getEmployees(page, argument)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.employees = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  delete(id: number) {
    this.employeeService.deleteEmployee(id)
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.deleteId = 0;
          this.ngOnInit();
        },
        error => {
          this.modalService.closeDefault();
          this.deleteId = 0;
          this.error = error;
        });
  }

  showDeleteModal(content, id) {

    this.deleteId = id;

    this.modalService.openDefault(content, null).result.then(
      (closeResult) => {
        if (closeResult == "send")
          console.log("modal closed : ", closeResult);
      }, (dismissReason) => {
        console.log("modal dismissed when used pressed ESC button");
      });
  }

}
