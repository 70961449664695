import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDefaultService } from '../../modal-default/modal-default.service';
import { Called } from '../../_models/called';
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/authentication.service';
import { CalledService } from '../../_services/called.service';
import { EmployeeService } from '../../_services/employee.service';
import { OccurrenceService } from '../../_services/occurrence.service';
import { RouteService } from '../../_services/route.service';
import { SolutionService } from '../../_services/solution.service';

@Component({
  selector: 'app-called-list',
  templateUrl: './called-list.component.html',
  styleUrls: ['./called-list.component.css']
})
export class CalledListComponent implements OnInit {

  calleds: Called[];

  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;

  routeList: any[];
  statusList: any[];

  idCalled = 0;
  submittedCancel = false;
  submittedAttendance = false;

  searchForm: FormGroup;
  cancelForm: FormGroup;
  attendanceForm: FormGroup;

  occurrenceList: any[];
  solutionList: any[];
  employeesList: any[];

  loggedUser: User;

  statusSelected: any[] = [];
  selectAllStatus: boolean = false;

  isThirdParty = false;

  showRoutes = false;

  constructor(private formBuilder: FormBuilder, private calledService: CalledService, private routeService: RouteService, private modalService: ModalDefaultService, private occurrenceService: OccurrenceService,
    private solutionService: SolutionService, private employeeService: EmployeeService, private authenticationService: AuthenticationService) { }

  ngOnInit() {

    this.authenticationService.currentUser.subscribe(user => {
      this.loggedUser = user;
    });

    this.showRoutes = this.loggedUser.permissions.filter(p => p == 'EditCalled').length > 0;

    this.getRoutes();
    this.getStatus();

    this.searchForm = this.formBuilder.group({ customer: [''], attendancePlace: [''], idRoute: [''], status: [''], fromDate: [''], toDate: [''], calledNumber: [''] });

    this.get(this.page, this.searchForm.controls.customer.value, this.searchForm.controls.attendancePlace.value, this.searchForm.controls.idRoute.value,
      this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.calledNumber.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.customer.value, this.searchForm.controls.attendancePlace.value, this.searchForm.controls.idRoute.value,
      this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.calledNumber.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.customer.value, this.searchForm.controls.attendancePlace.value, this.searchForm.controls.idRoute.value,
      this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.calledNumber.value);
  }

  private get(page: number, customer: string, attendancePlace: string, idRoute: string, fromDate: Date, toDate: Date, calledNumber: string) {
    this.submitted = true;

    this.loading = true;

    var statusList = [];

    //if (this.statusSelected) {
    //  for (var i = 0; i < this.statusSelected.length; i++) {
    //    statusList.push(this.statusSelected[i].id);
    //  }
    //}

    if (this.searchForm.controls.status.value) {
      statusList.push(this.searchForm.controls.status.value);
    }

    this.calledService.getCalleds(page, customer, attendancePlace, idRoute, statusList, fromDate, toDate, calledNumber)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.calleds = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  private getRoutes() {
    this.routeService.getRoutesActives()
      .subscribe(
        data => {
          this.routeList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getSolutions() {
    this.solutionService.getSolutionList()
      .subscribe(
        data => {
          this.solutionList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getOccurrences() {
    this.occurrenceService.getOccurrenceList()
      .subscribe(
        data => {
          this.occurrenceList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getEmployees() {
    this.employeeService.getEmployeeList()
      .subscribe(
        data => {
          this.employeesList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getStatus() {
    this.calledService.getStatus()
      .subscribe(
        data => {
          this.statusList = data;
        },
        error => {
          this.error = error;
        });
  }

  cancelCalled(id: number) {

    this.submittedCancel = true;

    if (this.cancelForm.invalid) {
      return;
    }

    this.calledService.cancelCalled(id, this.cancelForm.controls.cancelObservation.value)
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.idCalled = 0;
          this.submittedCancel = false;
          this.ngOnInit();
        },
        error => {
          this.modalService.closeDefault();
          this.submittedCancel = false;
          this.idCalled = 0;
          this.error = error;
        });
  }

  showCancelModal(content, id) {

    this.cancelForm = this.formBuilder.group({ cancelObservation: ['', Validators.required] });

    this.idCalled = id;
    this.modalService.openDefault(content, "lg");
  }

  showAttendanceModal(content, id, isThirdParty) {

    this.submittedAttendance = false;

    this.attendanceForm = this.formBuilder.group({
      idAttendanceOccurrence: ['', Validators.required], attendanceObservation: ['', Validators.required], idSolution: ['', Validators.required],
      solutionObservation: ['', Validators.required], inOperation: [false], pendency: [false], pendencyObservation: [''], escortName: ['', Validators.required],
      idAttendanceResponsible: [''], endDate: [null], startDate: [null], thirdPartyName: [null]
    });

    this.getOccurrences();
    this.getSolutions();
    this.getEmployees();

    this.idCalled = id;
    this.isThirdParty = isThirdParty;

    this.modalService.openDefault(content, "lg");
  }

  finalizeAttendance(id: number) {

    this.submittedAttendance = true;

    if (this.attendanceForm.controls.pendency.value && !this.attendanceForm.controls.pendencyObservation.value) {
      this.attendanceForm.controls.pendencyObservation.setErrors({ 'invalid': true });
      return;
    }

    if (this.isThirdParty && !this.attendanceForm.controls.thirdPartyName.value) {
      this.attendanceForm.controls.thirdPartyName.setErrors({ 'invalid': true });
      return;
    }

    if (this.attendanceForm.invalid) {
      return;
    }

    const newCalled = new Called();
    newCalled.id = id;
    newCalled.idAttendanceOccurrence = this.attendanceForm.controls.idAttendanceOccurrence.value;
    newCalled.attendanceObservation = this.attendanceForm.controls.attendanceObservation.value;
    newCalled.idSolution = this.attendanceForm.controls.idSolution.value;
    newCalled.solutionObservation = this.attendanceForm.controls.solutionObservation.value;
    newCalled.inOperation = this.attendanceForm.controls.inOperation.value;
    newCalled.pendency = this.attendanceForm.controls.pendency.value;
    newCalled.pendencyObservation = this.attendanceForm.controls.pendencyObservation.value;
    newCalled.escortName = this.attendanceForm.controls.escortName.value;
    newCalled.idAttendanceResponsible = this.attendanceForm.controls.idAttendanceResponsible.value ? this.attendanceForm.controls.idAttendanceResponsible.value : null;
    newCalled.endDate = this.attendanceForm.controls.endDate.value ? this.attendanceForm.controls.endDate.value : null;
    newCalled.startDate = this.attendanceForm.controls.startDate.value ? this.attendanceForm.controls.startDate.value : null;
    newCalled.thirdPartyName = this.attendanceForm.controls.thirdPartyName.value;

    this.calledService.finalizeCalled(this.idCalled, newCalled)
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.idCalled = 0;
          this.ngOnInit();
        },
        error => {
          this.modalService.closeDefault();
          this.idCalled = 0;
          this.ngOnInit();
        });
  }

  showStartAttendanceModal(content, contentStaff, id, isThirdParty) {

    if (this.loggedUser.profile == 1) {
      this.showAttendanceModal(contentStaff, id, isThirdParty)
    }
    else {
      this.idCalled = id;
      this.modalService.openDefault(content, "lg");
    }
  }

  startAttendance(id: number) {

    this.calledService.startCalled(id)
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.idCalled = 0;
          this.ngOnInit();
        },
        error => {
          this.modalService.closeDefault();
          this.idCalled = 0;
          this.error = error;
        });
  }

  toggleCheckAllStatus(values: any) {
    if (values.currentTarget.checked) {
      this.statusSelected = this.statusList;
    }
    else {
      this.statusSelected = [];
    }
  }
}
