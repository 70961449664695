import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntegrationSyncService {

  constructor(private repo: RepositoryService) { }

  syncAll() {
    return this.repo.post('integrations/all', {})
      .pipe(map((messageList: string[]) => {
        return messageList;
      }));
  }

}
