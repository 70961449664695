import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Occurrence } from '../_models/occurrence';
import { PaginatedResponse } from '../_models/paginated-response';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class OccurrenceService {

  constructor(private repo: RepositoryService) { }

  getOccurrences(page: number, argument: string) {
    let urlParams = '?page=' + page;

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('occurrences' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Occurrence>) => {
        return paginatedResponse;
      }));
  }

  getOccurrence(id: number) {
    return this.repo.getData('occurrences/' + id)
      .pipe(map((occurrence: Occurrence) => {
        return occurrence;
      }));
  }

  getOccurrenceList() {
    return this.repo.getData('occurrences/all')
      .pipe(map((occurrences: Occurrence[]) => {
        return occurrences;
      }));
  }

  createOrUpdate(id: number, occurrence: Occurrence) {
    if (id && id > 0) {
      return this.repo.put('occurrences/' + id, occurrence);
    }
    else {
      occurrence.id = null;
      return this.repo.post('occurrences', occurrence);
    }
  }
}
