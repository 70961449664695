import { Component, OnInit } from '@angular/core';
import { RouteService } from '../../_services/route.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteModel } from '../../_models/route-model';
import { EmployeeService } from '../../_services/employee.service';

@Component({
  selector: 'app-route-detail',
  templateUrl: './route-detail.component.html',
  styleUrls: ['./route-detail.component.css']
})
export class RouteDetailComponent implements OnInit {
  detailForm: FormGroup;
  idRoute = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';
  employees: any[] = [];
  employeesList: any[];

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private routeService: RouteService, private employeeService: EmployeeService) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({
      idExternal: [''], routeName: ['', Validators.required], thirdPartyCompany: [false], employeeSelected: [null]
    });

    this.activeRoute.params.subscribe(routeParams => {
      this.idRoute = parseInt(routeParams.id);
    });

    if (this.idRoute > 0) {

      this.detailForm.disable();

      this.routeService.getRoute(this.idRoute)
        .subscribe(
          data => {
            this.detailForm.setValue({
              routeName: data.name, thirdPartyCompany: data.thirdPartyCompany, idExternal: data.idExternal, employeeSelected: null
            });

            this.employees = data.employees;
          },
          error => {
            this.router.navigate(['route']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
    this.getEmployees();
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newRoute = new RouteModel();
    newRoute.id = this.idRoute;
    newRoute.idExternal = parseInt(this.detailForm.controls.idExternal.value);
    newRoute.name = this.detailForm.controls.routeName.value;
    newRoute.thirdPartyCompany = this.detailForm.controls.thirdPartyCompany.value;
    newRoute.employees = this.employees;

    this.routeService.createOrUpdate(this.idRoute, newRoute)
      .subscribe(
        data => {
          this.router.navigate(['route/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  addEmployee() {

    const newEmp = this.detailForm.controls.employeeSelected.value
    this.detailForm.controls.employeeSelected.setValue(null);

    if (newEmp != null) {

      for (let i = 0; i < this.employees.length; i++) {
        if (newEmp.id == this.employees[i].id) {
          return;
        }
      }

      this.employees.push(newEmp);
    }
  }

  private getEmployees() {
    this.employeeService.getEmployeeList()
      .subscribe(
        data => {
          this.employeesList = data;
        },
        error => {
          this.error = error;
        });
  }
}
