import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AttendancePlace } from '../../_models/attendance-place';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { ContractService } from '../../_services/contract.service';
import { ReportService } from '../../_services/report.service';
import { RouteService } from '../../_services/route.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-attendance-per-customer',
  templateUrl: './attendance-per-customer.component.html',
  styleUrls: ['./attendance-per-customer.component.css']
})
export class AttendancePerCustomerComponent implements OnInit {
  attendancePlaceList: any[];
  attendanceList: any[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  loadingExport = false;
  statusList: any[];

  formatter = (place: AttendancePlace) => place.name;

  constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router, private reportService: ReportService, private attendancePlaceService: AttendancePlaceService, private contractService: ContractService) { }

  ngOnInit() {

    this.getContractStatus();

    this.searchForm = this.formBuilder.group({ idAttendancePlace: ['', Validators.required], contract: [''] });

    this.get(this.page, this.searchForm.controls.idAttendancePlace.value, this.searchForm.controls.contract.value)
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.idAttendancePlace.value, this.searchForm.controls.contract.value)
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.idAttendancePlace.value, this.searchForm.controls.contract.value)
  }

  private get(page: number, attendancePlace: any, contract: number) {
    this.submitted = true;

    this.loading = true;

    var idAttendancePlace = attendancePlace != null ? attendancePlace.id : null;

    this.reportService.getAttendancePerCustomer(page, idAttendancePlace, contract)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.attendanceList = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  //private getAttendancePlaces() {
  //  this.attendancePlaceService.getAttendancePlacesActives()
  //    .subscribe(
  //      data => {
  //        this.attendancePlaceList = data;
  //      },
  //      error => {
  //        this.error = error;
  //      });
  //}

  private getContractStatus() {
    this.contractService.getContractStatus()
      .subscribe(
        data => {
          this.statusList = data;
        },
        error => {
          this.error = error;
        });
  }

  getAttendancePlaces = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.attendancePlaceService.getByArgument(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  exportAttendancePerCustomer() {

    if (!this.searchForm.controls.idAttendancePlace.value) {
      return;
    }

    this.reportService.exportAttendancePerCustomer(this.searchForm.controls.idAttendancePlace.value.id, this.searchForm.controls.contract.value)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'relatorio-manutencao-por-local.xlsx');
        });
  }
}
