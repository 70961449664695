import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { AttendancePlace } from '../_models/attendance-place';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';
import { Attendance } from '../_models/attendance';
import { AttendancePlaceInput } from '../_models/attendance-place-input';

@Injectable({
  providedIn: 'root'
})
export class AttendancePlaceService {

  constructor(private repo: RepositoryService) { }

  getAttendancePlaces(page: number, idCustomer: number, idRoute: number, name: string, maintenanceDayOfMonth: number, addressName: string, customerArgument: string, contractCode: string, routeName: string) {
    let urlParams = '?page=' + page;

    if (idCustomer > 0) {
      urlParams += '&idCustomer=' + idCustomer;
    }

    if (idRoute > 0) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (name) {
      urlParams += '&argument=' + name;
    }

    if (maintenanceDayOfMonth) {
      urlParams += '&maintenanceDayOfMonth=' + maintenanceDayOfMonth;
    }

    if (addressName) {
      urlParams += '&addressName=' + addressName;
    }

    if (customerArgument) {
      urlParams += '&customerArgument=' + customerArgument;
    }

    if (contractCode) {
      urlParams += '&contractCode=' + contractCode;
    }

    if (routeName) {
      urlParams += '&routeName=' + routeName;
    }

    return this.repo.getData('attendancePlaces' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<AttendancePlace>) => {
        return paginatedResponse;
      }));
  }

  getAttendancePlace(id: number) {
    return this.repo.getData('attendancePlaces/' + id)
      .pipe(map((attendancePlace: AttendancePlace) => {
        return attendancePlace;
      }));
  }

  getAttendancePlacesByName(name: string) {
    return this.repo.getData('attendancePlaces/name/' + name + '/list')
      .pipe(map((attendancePlaces: AttendancePlace[]) => {
        return attendancePlaces;
      }));
  }

  getAttendances(page: number, month: number, year: number, customer: string, place: string, address: string, idRoute: number) {
    let urlParams = '?page=' + page;

    if (month) {
      urlParams += '&month=' + month;
    }

    if (year) {
      urlParams += '&year=' + year;
    }

    if (customer) {
      urlParams += '&customer=' + customer;
    }

    if (place) {
      urlParams += '&place=' + place;
    }

    if (address) {
      urlParams += '&address=' + address;
    }

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    return this.repo.getData('attendancePlaces/attendances' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Attendance>) => {
        return paginatedResponse;
      }));
  }

  createOrUpdate(id: number, attendancePlace: AttendancePlaceInput) {
    if (id && id > 0) {
      return this.repo.put('attendancePlaces/' + id, attendancePlace);
    }
    else {
      attendancePlace.id = null;
      return this.repo.post('attendancePlaces', attendancePlace);
    }
  }

  getStatus() {
    return this.repo.getData('attendancePlaces/status')
      .pipe(map((statusList: any[]) => {
        return statusList;
      }));
  }

  getAttendancePlacesByCustomer(idCustomer: number) {
    return this.repo.getData('attendancePlaces/customer/' + idCustomer)
      .pipe(map((attendancePlaces: AttendancePlace[]) => {
        return attendancePlaces;
      }));
  }

  getAttendancePlacesByContract(idContract: number) {
    return this.repo.getData('attendancePlaces/contract/' + idContract)
      .pipe(map((attendancePlaces: AttendancePlace[]) => {
        return attendancePlaces;
      }));
  }

  getAttendancePlacesActives() {
    return this.repo.getData('attendancePlaces/actives')
      .pipe(map((attendancePlaces: AttendancePlace[]) => {
        return attendancePlaces;
      }));
  }

  getByArgument(argument: string) {
    return this.repo.getData('attendancePlaces/list?argument=' + argument)
      .pipe(map((attendancePlaces: AttendancePlace[]) => {
        return attendancePlaces;
      }));
  }

  getByAnyArgument(argument: string) {
    return this.repo.getData('attendancePlaces/list/anyArgument?argument=' + argument)
      .pipe(map((attendancePlaces: AttendancePlace[]) => {
        return attendancePlaces;
      }));
  }
}
