import { Component, OnInit } from '@angular/core';
import { Contract } from '../../_models/contract';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ContractService } from '../../_services/contract.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.css']
})
export class ContractListComponent implements OnInit {
  contracts: Contract[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;

  constructor(private formBuilder: FormBuilder, private router: Router, private contractService: ContractService) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({ contractNumber: [''], customerArgument: [''] });

    this.getContracts(this.page, this.searchForm.controls.contractNumber.value, this.searchForm.controls.customerArgument.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.getContracts(this.page, this.searchForm.controls.contractNumber.value, this.searchForm.controls.customerArgument.value);
  }

  onSelectedPage() {
    this.getContracts(this.page, this.searchForm.controls.contractNumber.value, this.searchForm.controls.customerArgument.value);
  }

  private getContracts(page: number, contractNumber: string, customerArgument: string) {
    this.submitted = true;

    this.loading = true;
    this.contractService.getContracts(page, contractNumber, customerArgument)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.contracts = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

}
