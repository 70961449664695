import { AttendancePlace } from "./attendance-place";
import { Customer } from "./customer";
import { Equipment } from "./equipment";
import { ReasonRequest } from "./reason-request";
import { RouteModel } from "./route-model";
import { User } from "./user";

export class BudgetRequest {
  id: number;
  idCustomer: number;
  customer: Customer;
  idAttendancePlace: number;
  attendancePlace: AttendancePlace;
  idEquipment: number;
  equipment: Equipment;
  idRoute: number;
  route: RouteModel;
  idTechnicianUser: number;
  technicianUser: User;
  idStaffUser: number;
  staffUser: User;
  idReasonRequest: number;
  reasonRequest: ReasonRequest;
  idExternal: number;
  creationDate: Date;
  finalizationDate: Date;
  status: number;
  observation: string;
  requestNumber: string;
  items: any[];
  cancelationObservation: string;
  urgent: boolean;
  urgentReason: string;
}
