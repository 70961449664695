import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { PaginatedResponse } from '../_models/paginated-response';
import { map } from 'rxjs/operators';
import { Employee } from '../_models/employee';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private repo: RepositoryService) { }

  getEmployees(page: number, argument: string) {
    let urlParams = '?page=' + page;

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('employees' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Employee>) => {
        return paginatedResponse;
      }));
  }

  getEmployee(id: number) {
    return this.repo.getData('employees/' + id)
      .pipe(map((employee: Employee) => {
        return employee;
      }));
  }

  getEmployeeList() {
    return this.repo.getData('employees/all')
      .pipe(map((employees: Employee[]) => {
        return employees;
      }));
  }

  getEmployeesByName(name: string) {
    return this.repo.getData('employees/name/' + name)
      .pipe(map((employees: any[]) => {
        return employees;
      }));
  }

  createOrUpdate(id: number, employee: Employee) {
    if (id && id > 0) {
      return this.repo.put('employees/' + id, employee);
    }
    else {
      employee.id = null;
      return this.repo.post('employees', employee);
    }
  }

  deleteEmployee(id: number) {
    return this.repo.delete('employees/' + id);
  }
}
