import { Customer } from "./customer";
import { RouteModel } from "./route-model";
import { Contract } from "./contract";

export class AttendancePlaceInput {
  id: number;
  idExternal: number;
  idAddress: number;
  idCustomer: number;
  idRoute: number;
  idContract: number;
  name: string;
  status: number;
  maintenanceDayOfMonth: number;
  observationAttendance: string;
  state: string;
  city: string;
  description: string;
  zipCode: string;
}
