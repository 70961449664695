import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { PaginatedResponse } from '../_models/paginated-response';
import { map } from 'rxjs/operators';
import { Equipment } from '../_models/equipment';
import { EquipmentList } from '../_models/equipment-list';
import { Checklist } from '../_models/checklist';
import { EquipmentReport } from '../_models/equipment-report';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(private repo: RepositoryService) { }

  getEquipments(page: number, idAttendancePlace: number, argument: string) {
    let urlParams = '?page=' + page;

    if (idAttendancePlace > 0) {
      urlParams += '&idAttendancePlace=' + idAttendancePlace;
    }

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('equipments' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Equipment>) => {
        return paginatedResponse;
      }));
  }

  getEquipment(id: number) {
    return this.repo.getData('equipments/' + id)
      .pipe(map((equipment: Equipment) => {
        return equipment;
      }));
  }

  getEquipmentsByAttendancePlace(idAttendancePlace: number, year: number, month: number) {
    return this.repo.getData('equipments/attendancePlace/' + idAttendancePlace + '/year/' + year + '/month/' + month)
      .pipe(map((equipments: EquipmentList[]) => {
        return equipments;
      }));
  }

  getByAttendancePlace(idAttendancePlace: number) {
    return this.repo.getData('equipments/attendancePlace/' + idAttendancePlace)
      .pipe(map((equipments: EquipmentList[]) => {
        return equipments;
      }));
  }

  createEquipmentChecklist(idEquipment: number, idChecklist: number, associateAll: boolean) {

    return this.repo.post('equipments/' + idEquipment + '/allEquipments/' + associateAll + '/checklist', idChecklist)
      .pipe(map((checklist: any) => {
        return checklist as Checklist;
      }));
  }

  getEquipmentByQRCode(qrCode: number) {
    return this.repo.getData('equipments/qrCode/' + qrCode)
      .pipe(map((equipment: EquipmentReport) => {
        return equipment;
      }));
  }

  createOrUpdate(id: number, equipment: Equipment) {
    if (id && id > 0) {
      return this.repo.put('equipments/' + id, equipment);
    }
    else {
      equipment.id = null;
      return this.repo.post('equipments', equipment);
    }
  }
}
