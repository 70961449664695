import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ReasonRequest } from '../../_models/reason-request';
import { ReasonRequestService } from '../../_services/reason-request.service';

@Component({
  selector: 'app-reason-request-list',
  templateUrl: './reason-request-list.component.html',
  styleUrls: ['./reason-request-list.component.css']
})
export class ReasonRequestListComponent implements OnInit {

  reasonRequests: ReasonRequest[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;

  constructor(private formBuilder: FormBuilder, private reasonRequestService: ReasonRequestService) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({ argument: [''] });

    this.get(this.page, this.searchForm.controls.argument.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.argument.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.argument.value);
  }

  private get(page: number, argument: string) {
    this.submitted = true;

    this.loading = true;
    this.reasonRequestService.getReasonRequests(page, argument)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.reasonRequests = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
