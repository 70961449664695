import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExternalFileImportService } from '../_services/external-file-import.service';

@Component({
  selector: 'app-external-file-import',
  templateUrl: './external-file-import.component.html',
  styleUrls: ['./external-file-import.component.css']
})
export class ExternalFileImportComponent implements OnInit {
  detailForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  inputFile = null;

  accept = '*';
  myFormData: FormData = new FormData();

  logs: any[] = null;
  hasError = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private externalFileImportService: ExternalFileImportService) { }

  ngOnInit() {

  }

  deleteFile() {
    this.inputFile = null;
    this.hasError = false;
  }

  removeError() {
    this.hasError = false;
  }

  onSubmit() {

    this.logs = null;
    this.submitted = true;

    if (this.inputFile == null) {
      this.hasError = true;
      return;
    }

    this.loading = true;

    this.myFormData = new FormData();
    this.myFormData.append("file", this.inputFile, this.inputFile.name);

    this.externalFileImportService.importFile(this.myFormData)
      .subscribe(
        result => {

          if (result.data && result.data.messages) {
            this.logs = result.data.messages;
          }

          this.loading = false;
          this.submitted = false;
        },
        error => {
          this.loading = false;
          this.submitted = false;
        });
  }
}
