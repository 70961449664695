import { Address } from "./address";

export class Customer {
    id: number;
    personType: number;
    cpfCnpj: string;
    corporateName: string;
    businessName: string;
    idExternal: number;
    address: Address;
}
