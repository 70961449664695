import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BudgetRequest } from '../_models/budget-request';
import { PaginatedResponse } from '../_models/paginated-response';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class BudgetRequestService {

  constructor(private repo: RepositoryService) { }

  getBudgetRequests(page: number, customer: string, attendancePlace: string, technician: string, status: number, fromDate: Date, toDate: Date) {
    let urlParams = '?page=' + page;

    if (status) {
      urlParams += '&status=' + status;
    }

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (customer) {
      urlParams += '&customer=' + customer;
    }

    if (attendancePlace) {
      urlParams += '&attendancePlace=' + attendancePlace;
    }

    if (technician) {
      urlParams += '&technician=' + technician;
    }

    return this.repo.getData('budgetRequests' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<BudgetRequest>) => {
        return paginatedResponse;
      }));
  }

  getBudgetRequest(id: number) {
    return this.repo.getData('budgetRequests/' + id)
      .pipe(map((budgetRequests: BudgetRequest) => {
        return budgetRequests;
      }));
  }

  getBudgetRequestList() {
    return this.repo.getData('budgetRequests/all')
      .pipe(map((budgetRequests: BudgetRequest[]) => {
        return budgetRequests;
      }));
  }

  createOrUpdate(id: number, budgetRequest: BudgetRequest) {
    if (id && id > 0) {
      return this.repo.put('budgetRequests/' + id, budgetRequest)
        .pipe(map((response: any) => {
          return response.data as BudgetRequest;
        }));
    }
    else {
      budgetRequest.id = null;
      return this.repo.post('budgetRequests', budgetRequest)
        .pipe(map((response: any) => {
          return response.data as BudgetRequest;
        }));
    }
  }

  finalizeRequest(id: number) {
    return this.repo.put('budgetRequests/finalize/' + id, null)
      .pipe(map((response: any) => {
        return response.data as BudgetRequest;
      }));
  }

  cancelRequest(id: number, observation: string) {
    return this.repo.put('budgetRequests/cancel/' + id, { description: observation })
      .pipe(map((response: any) => {
        return response.data as BudgetRequest;
      }));
  }

  exportRequest(id: number) {
    return this.repo.getDataFile('budgetRequests/export/' + id);
  }

  createItem(id: number, content: any) {
    return this.repo.postWithFile('budgetRequests/' + id + '/item', content)
      .pipe(map((response: any) => {
        return response;
      }));
  }

  deleteItem(id: number) {
    return this.repo.put('budgetRequests/item/' + id, null);
  }

  getStatus() {
    return this.repo.getData('budgetRequests/status')
      .pipe(map((statusList: any[]) => {
        return statusList;
      }));
  }

  downloadFile(id, idFile) {
    return this.repo.getDataFile('budgetRequests/' + id + '/file/' + idFile + '/export');
  }
}
