import { Component } from '@angular/core';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast-global',
  templateUrl: './toast-global.component.html',
  styleUrls: ['./toast-global.component.css']
})
export class ToastGlobalComponent {

    constructor(public toastService: ToastService) { }

    showSuccess() {
        this.toastService.show('I am a success toast', { classname: 'bg-success text-light', delay: 5000 });
    }

    showDanger() {
        this.toastService.show('I am an error toast', { classname: 'bg-danger text-light', delay: 5000 });
    }
}
