import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Occurrence } from '../../_models/occurrence';
import { OccurrenceService } from '../../_services/occurrence.service';

@Component({
  selector: 'app-occurrence-detail',
  templateUrl: './occurrence-detail.component.html',
  styleUrls: ['./occurrence-detail.component.css']
})
export class OccurrenceDetailComponent implements OnInit {

  detailForm: FormGroup;
  idOccurrence = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private occurrenceService: OccurrenceService) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({ occurrenceName: ['', Validators.required] });

    this.activeRoute.params.subscribe(routeParams => {
      this.idOccurrence = parseInt(routeParams.id);
    });

    if (this.idOccurrence > 0) {

      this.detailForm.disable();

      this.occurrenceService.getOccurrence(this.idOccurrence)
        .subscribe(
          data => {
            this.detailForm.setValue({
              occurrenceName: data.name
            });
          },
          error => {
            this.router.navigate(['route']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newOccurrence = new Occurrence();
    newOccurrence.id = this.idOccurrence;
    newOccurrence.name = this.detailForm.controls.occurrenceName.value;

    this.occurrenceService.createOrUpdate(this.idOccurrence, newOccurrence)
      .subscribe(
        data => {
          this.router.navigate(['occurrence/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
