import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../_services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../../_services/employee.service';
import { Employee } from '../../_models/employee';
import { ContactService } from '../../_services/contact.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, catchError } from 'rxjs/operators';
import { User } from '../../_models/user';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
    editForm: FormGroup;
    idUser = 0;
    editMode = false;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    profileList = [];
    showEmployees = false;
    showContacts = false;
    employee = null;
    contact = null;
    
  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private userService: UserService,
              private employeeService: EmployeeService,  private contactService: ContactService) { }

    ngOnInit() {

        this.getProfileTypes();

        this.editForm = this.formBuilder.group({
            login: [''], cpf: ['', Validators.required], name: ['', Validators.required], email: [''], profile: ['', Validators.required], phoneNumber: [''], employee: [''], contact: [''], idEmployee: ['']
        });

        this.activeRoute.params.subscribe(routeParams => {
            this.idUser = parseInt(routeParams.id);
        });

        if (this.idUser > 0) {

            this.editForm.disable();

            this.userService.getUser(this.idUser)
                .subscribe(
                  data => {
                    this.editForm.setValue({ login: data.login, cpf: data.cpf, name: data.name, email: data.email, phoneNumber: data.phoneNumber, profile: data.profile, employee: '', contact: '', idEmployee: data.idEmployee });
                    },
                    error => {
                        this.router.navigate(['user/list']);
                    });
        }
        else {
            this.setEditMode();
        }
    }

    onSubmit() {
      
        this.submitted = true;

        if (!this.editForm.controls.email.value && !this.editForm.controls.phoneNumber.value) {
            return
        }

        if (this.editForm.invalid) {
            return;
        }

        this.loading = true;
      
        var newUser = new User();
        newUser.id = this.idUser;
        newUser.login = this.editForm.controls.cpf.value;
        newUser.cpf = this.editForm.controls.cpf.value;
        newUser.name = this.editForm.controls.name.value;
        newUser.email = this.editForm.controls.email.value;
        newUser.profile = this.editForm.controls.profile.value;
        newUser.phoneNumber = this.editForm.controls.phoneNumber.value;
        newUser.idEmployee = this.editForm.controls.idEmployee.value ? parseInt(this.editForm.controls.idEmployee.value) : null;

        this.userService.createOrUpdateUser(this.idUser, newUser)
            .subscribe(
                data => {
                    this.router.navigate(['user/list']);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    setEditMode() {
        this.editMode = true;
        this.editForm.enable();
    }

    changeForm() {

        this.editForm.controls.cpf.setValue('');
        this.editForm.controls.login.setValue('');
        this.editForm.controls.name.setValue('');
        this.editForm.controls.contact.setValue('');
        this.editForm.controls.employee.setValue('');
        this.editForm.controls.idEmployee.setValue('');

        if (this.editForm.controls.profile.value == 1 || this.editForm.controls.profile.value == 2) {
            this.editForm.controls.cpf.disable();
            this.editForm.controls.name.disable();
            this.showEmployees = true;
            this.showContacts = false;
        }
        else
        if (this.editForm.controls.profile.value == 3) {
            this.editForm.controls.cpf.enable();
            this.editForm.controls.name.enable();
            this.showEmployees = false;
            this.showContacts = true
        }
        else {
            this.editForm.controls.cpf.enable();
            this.editForm.controls.name.enable();
            this.showEmployees = false;
            this.showContacts = false;
        }
    }

    setForm() {

        if (this.editForm.controls.employee.value && this.showEmployees) {
            this.editForm.controls.cpf.setValue(this.editForm.controls.employee.value.cpf);
            this.editForm.controls.login.setValue(this.editForm.controls.employee.value.cpf);
            this.editForm.controls.name.setValue(this.editForm.controls.employee.value.name);
            this.editForm.controls.idEmployee.setValue(this.editForm.controls.employee.value.id);
        }
      
        if (this.editForm.controls.contact.value && this.showContacts) {
            this.editForm.controls.cpf.setValue(this.editForm.controls.contact.value.document);
            this.editForm.controls.login.setValue(this.editForm.controls.contact.value.document);
            this.editForm.controls.name.setValue(this.editForm.controls.contact.value.name);
        }
    }

    private getProfileTypes() {
        this.userService.getProfileTypes()
            .subscribe(
                data => {
                    this.profileList = data;
                },
                error => {
                    this.error = error;
                });
    }

    getEmployees = (text$: Observable<any>) => text$.pipe(debounceTime(500), distinctUntilChanged(), filter(term => term.length >= 3), switchMap(term =>
        this.employeeService.getEmployeesByName(term).pipe(
        catchError(() => {
          return of([]);
        }))));

    getContacts = (text$: Observable<any>) => text$.pipe(debounceTime(500), distinctUntilChanged(), filter(term => term.length >= 3), switchMap(term =>
        this.contactService.getContactsByName(term).pipe(
        catchError(() => {
          return of([]);
        }))));

    inputFormatValue(value: any) {
        if (value.name)
           return value.name
        return value;
    }

    resultFormatValue(value: any) {
        return value.name;
    }
}
