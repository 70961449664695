import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AttendanceService } from '../../_services/attendance.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/authentication.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {
  points = [];
  idAttendance: number;
  editForm: FormGroup;
  submitted = false;
  loading = false;
  attendance = {
    customerName: '',
    attendancePlaceName: ''
  };

  signatureRequired = false;

  signatureFile: any = null;
  accept = '*';

  hasStaffPermission = false;

  onlyAttachmentSignature = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private attendanceService: AttendanceService, private authenticationService: AuthenticationService, private activeRoute: ActivatedRoute) { }

  ngOnInit() {

    if (this.authenticationService.currentUserValue) {
      this.hasStaffPermission = this.authenticationService.currentUserValue.permissions.filter(f => f == 'StartAttendanceWithDate')[0] != null;
      //this.hasStaffPermission = false;
    }

    this.editForm = this.formBuilder.group({
      responsibleName: ['', Validators.required], responsibleDocument: ['', Validators.required]
    });

    this.activeRoute.params.subscribe(routeParams => {
      this.idAttendance = parseInt(routeParams.idAttendance);
    });

    this.attendanceService.getAttendanceToSubscribe(this.idAttendance)
      .subscribe(
        data => {
          this.attendance = data;
        },
        error => {
          this.router.navigate(['/attendance/list']);
        });
  }

  hideKeyboard() {

    var field = document.createElement('input');
    field.setAttribute('type', 'text');
    field.setAttribute('style', 'width: 0px; height: 0px; border-color: white;');
    document.body.appendChild(field);

    setTimeout(function () {
      field.focus();
      setTimeout(function () {
        field.setAttribute('style', 'display:none;');
      }, 10);
    }, 10);
  }

  onSubmitSignature(signatureImage: Blob) {
    this.submitted = true;

    if (this.editForm.invalid || !signatureImage) {
      return;
    }

    this.loading = true;

    var myFormData = new FormData()

    myFormData.append('responsibleName', this.editForm.controls.responsibleName.value);
    myFormData.append('responsibleDocument', this.editForm.controls.responsibleDocument.value);
    myFormData.append('file', new File([signatureImage], 'generic-name.png', { type: signatureImage.type, lastModified: Date.now() }));

    this.attendanceService.saveSignature(this.idAttendance, myFormData)
      .subscribe(
        data => {
          this.router.navigate(['/attendance/list']);
        },
        error => {
          this.loading = false;
        });
  }

  onSubmit() {
    this.submitted = true;

    if (this.editForm.invalid || !this.signatureFile) {
      return;
    }

    this.loading = true;

    var myFormData = new FormData()

    myFormData.append('responsibleName', this.editForm.controls.responsibleName.value);
    myFormData.append('responsibleDocument', this.editForm.controls.responsibleDocument.value);
    myFormData.append('file', new File([this.signatureFile], 'generic-name.png', { type: this.signatureFile.type, lastModified: Date.now() }));

    this.attendanceService.saveSignature(this.idAttendance, myFormData)
      .subscribe(
        data => {
          this.router.navigate(['/attendance/list']);
        },
        error => {
          this.loading = false;
        });
  }

  deleteFile() {
    this.signatureFile = null;
  }

  downloadReport() {

    this.attendanceService.getReportFile(this.idAttendance)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'Relatorio-' + this.idAttendance + '.docx');
        },
        error => {
          //this.router.navigate(['diagnosticoRelatorio/lista']);
        });
  }
}
