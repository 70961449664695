import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { RouteModel } from '../_models/route-model';
import { PaginatedResponse } from '../_models/paginated-response';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private repo: RepositoryService) { }

  getRoutes(page: number, idEmployee: number, routeName: string, thirdPartyCompany: boolean, employeeName) {
    let urlParams = '?page=' + page;

    if (idEmployee > 0) {
      urlParams += '&idEmployee=' + idEmployee;
    }

    if (routeName) {
      urlParams += '&argument=' + routeName;
    }

    if (thirdPartyCompany) {
      urlParams += '&thirdPartyCompany=' + thirdPartyCompany;
    }

    if (employeeName) {
      urlParams += '&employeeName=' + employeeName;
    }

    return this.repo.getData('routes' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<RouteModel>) => {
        return paginatedResponse;
      }));
  }

  getRoute(id: number) {
    return this.repo.getData('routes/' + id)
      .pipe(map((route: RouteModel) => {
        return route;
      }));
  }

  createOrUpdate(id: number, routeModel: RouteModel) {
    if (id && id > 0) {
      return this.repo.put('routes/' + id, routeModel);
    }
    else {
      routeModel.id = null;
      return this.repo.post('routes', routeModel);
    }
  }

  getRoutesActives() {
    return this.repo.getData('routes/actives')
      .pipe(map((routes: RouteModel[]) => {
        return routes;
      }));
  }

  getRoutesByEmployee(idEmployee: number) {
    return this.repo.getData('routes/employee/' + idEmployee)
      .pipe(map((routes: RouteModel[]) => {
        return routes;
      }));
  }
}
