import { Component, OnInit } from '@angular/core';
import { Equipment } from '../../_models/equipment';
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { EquipmentService } from '../../_services/equipment.service';
import { ModalDefaultService } from '../../modal-default/modal-default.service';
import { Checklist } from '../../_models/checklist';
import { ChecklistService } from '../../_services/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { AttendancePlace } from '../../_models/attendance-place';

@Component({
    selector: 'app-equipment-list',
    templateUrl: './equipment-list.component.html',
    styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent implements OnInit {
    equipments: Equipment[];
    checklists: Checklist[];
    searchForm: FormGroup;
    checklistForm: FormGroup;
    loading = false;
    error = '';
    page = 1;
    totalResults = 0;
    resultsPerPage = 0;
    idAttendancePlace = 0;
    idChecklistRoute = 0;
    attendancePlace: AttendancePlace;

    constructor(private formBuilder: FormBuilder, private equipmentService: EquipmentService, private modalService: ModalDefaultService, private checklistService: ChecklistService, private activeRoute: ActivatedRoute, private attendancePlaceService: AttendancePlaceService, private router: Router) { }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({ argument: [''] });

        this.activeRoute.queryParams.subscribe(params => {
            this.idAttendancePlace = params.idAttendancePlace;
        });

        if (this.idAttendancePlace) {
            this.getAttendancePlace();
        }

        this.getEquipments(this.page, this.idAttendancePlace, this.searchForm.controls.argument.value);
    }

    onSubmit() {
        if (this.searchForm.invalid) {
            return;
        }

        this.page = 1;
        this.getEquipments(this.page, this.idAttendancePlace, this.searchForm.controls.argument.value);
    }

    onSelectedPage() {
        this.getEquipments(this.page, this.idAttendancePlace, this.searchForm.controls.argument.value);
    }

    showModal(content, _idEquipment, _idChecklist) {
      
        this.checklistForm = this.formBuilder.group({ idEquipment: [_idEquipment], selectedChecklist: [_idChecklist], associateAll: false });

        this.setChecklistRoute();

        this.getChecklists();

        this.modalService.openDefault(content, "lg");
    }

    onSubmitChecklist() {
      
      this.equipmentService.createEquipmentChecklist(this.checklistForm.controls.idEquipment.value, this.checklistForm.controls.selectedChecklist.value, this.checklistForm.controls.associateAll.value)
            .subscribe(
                data => {
                    if (data) {
                        this.modalService.closeDefault();
                        this.getEquipments(this.page, this.idAttendancePlace, this.searchForm.controls.argument.value);
                    }
                },
                error => {
                    this.error = error;
                    this.modalService.closeDefault();
                });
    }

    setChecklistRoute() {        
        this.idChecklistRoute = this.checklistForm.controls.selectedChecklist.value;
    }

    private getEquipments(page: number, idAttendancePlace: number, argument: string) {
        this.loading = true;
        this.equipmentService.getEquipments(page, idAttendancePlace, argument)
            .subscribe(
                data => {
                    this.page = data.pageNumber;
                    this.resultsPerPage = data.resultsPerPage;
                    this.totalResults = data.totalResults;
                    this.equipments = data.response;
                    this.loading = false;
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    private getChecklists() {

        this.checklistService.getAllChecklists()
            .subscribe(
                data => {
                    this.checklists = data;
                },
                error => {
                    this.error = error;
                });
    }
  
    private getAttendancePlace() {
        this.attendancePlaceService.getAttendancePlace(this.idAttendancePlace)
          .subscribe(
            data => {
              this.attendancePlace = data;
            },
            error => {
              this.router.navigate(['customer/list']);
            });
    }
}
