import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../_models/user';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    public loggedUser: User;
    public step = 0;

    constructor(private activeRoute: ActivatedRoute, private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.activeRoute.params.subscribe(routeParams => {
            this.step = routeParams.step;
        });

        this.authenticationService.currentUser.subscribe(user => {
            this.loggedUser = user;
        });
    }
}
