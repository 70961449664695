import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalDefaultService {

    constructor(private modalService: NgbModal) { }

    openDefault(content: any, sizeOption: "sm" | "lg" | "xl") {
        return this.modalService.open(content,
            {
                backdrop: 'static',
                size: sizeOption
            });
    }

    closeDefault() {
        this.modalService.dismissAll();
    }
}
