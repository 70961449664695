import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AttendancePlace } from '../../_models/attendance-place';
import { Called } from '../../_models/called';
import { Equipment } from '../../_models/equipment';
import { User } from '../../_models/user';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { CalledService } from '../../_services/called.service';
import { EmployeeService } from '../../_services/employee.service';
import { EquipmentService } from '../../_services/equipment.service';
import { OccurrenceService } from '../../_services/occurrence.service';
import { SolutionService } from '../../_services/solution.service';

@Component({
  selector: 'app-called-detail',
  templateUrl: './called-detail.component.html',
  styleUrls: ['./called-detail.component.css']
})
export class CalledDetailComponent implements OnInit {

  detailForm: FormGroup;
  idCalled = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';
  equipmentList: any[] = [];
  occurrenceList: any[] = [];
  solutionList: any[] = [];
  employeesList: any[] = [];
  isThirdParty = false;

  inactiveContract = false;

  formatter = (attendancePlace: AttendancePlace) => attendancePlace.name;

  calledStatus = 0;

  today = new Date();

  attendanceResponsible: User = null;
  finishingResponsible: User = null;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private calledService: CalledService, private attendancePlaceService: AttendancePlaceService,
    private equipmentService: EquipmentService, private occurrenceService: OccurrenceService, public datepipe: DatePipe, private solutionService: SolutionService, private employeeService: EmployeeService) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({
      statusName: [''], calledNumber: [''], attendancePlace: ['', Validators.required], contract: [''], idEquipment: ['', Validators.required], requesterName: ['', Validators.required],
      requesterPhone: [''], creationDate: [this.datepipe.transform(this.today, 'yyyy-MM-dd HH:mm')], idInformedOccurrence: ['', Validators.required], informedObservation: ['', Validators.required], startDate: [null], idAttendanceResponsible: [null],
      idFinishingResponsible: [null], endDate: [''], idAttendanceOccurrence: [null], attendanceObservation: [''], idSolution: [null], solutionObservation: [''], inOperation: [false], pendency: [false], pendencyText: [''],
      pendencyObservation: [''], escortName: [''], equipmentBrand: [null], equipmentModel: [null], equipmentCode: [null], thirdPartyName: [null]
    });

    this.activeRoute.params.subscribe(routeParams => {
      this.idCalled = parseInt(routeParams.id);
    });

    this.getEmployees();
    this.getOccurrences();
    this.getSolutions();

    if (this.idCalled > 0) {

      this.detailForm.disable();

      this.calledService.getCalled(this.idCalled)
        .subscribe(
          data => {

            if (data.attendanceResponsible) {
              this.attendanceResponsible = data.attendanceResponsible;
            }

            if (data.finishingResponsible) {
              this.finishingResponsible = data.finishingResponsible;
            }

            this.detailForm.setValue({
              statusName: data.statusName, calledNumber: data.calledNumber, attendancePlace: data.attendancePlace, contract: data.attendancePlace.contract.contractNumber, idEquipment: data.equipment.id,
              requesterName: data.requesterName, requesterPhone: data.requesterPhone, creationDate: data.creationDate, idInformedOccurrence: data.informedOccurrence.id, informedObservation: data.informedObservation,
              startDate: data.startDate, idAttendanceResponsible: data.attendanceResponsible != null && data.attendanceResponsible.idEmployee != null ? data.attendanceResponsible.idEmployee : null, idFinishingResponsible: data.finishingResponsible != null && data.finishingResponsible.idEmployee != null ? data.finishingResponsible.idEmployee : null,
              endDate: data.endDate, idAttendanceOccurrence: data.attendanceOccurrence != null ? data.attendanceOccurrence.id : '', attendanceObservation: data.attendanceObservation, idSolution: data.solution != null ? data.solution.id : '',
              solutionObservation: data.solutionObservation, inOperation: data.inOperation, pendencyText: data.pendency ? "Sim" : "Não", pendency: data.pendency, pendencyObservation: data.pendencyObservation,
              escortName: data.escortName, equipmentBrand: data.equipment.brand != null ? data.equipment.brand.name : "", equipmentModel: data.equipment.model, equipmentCode: data.equipment.qrCode, thirdPartyName: data.thirdPartyName,
            });

            this.calledStatus = data.status;

            if (data.attendancePlace) {
              this.getEquipments(data.attendancePlace.id);

              this.isThirdParty = data.attendancePlace.route.thirdPartyCompany;
            }
          },
          error => {
            this.router.navigate(['route']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  onSubmit() {

    this.submitted = true;

    if (this.calledStatus > 1) {
      if (!this.detailForm.controls.thirdPartyName.value && this.isThirdParty) {
        this.detailForm.controls.thirdPartyName.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.idAttendanceResponsible.value) {
        this.detailForm.controls.idAttendanceResponsible.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.startDate.value) {
        this.detailForm.controls.startDate.setErrors({ 'invalid': true });
      }
    }

    if (this.calledStatus > 2 && this.calledStatus != 5) {
      if (!this.detailForm.controls.idFinishingResponsible.value) {
        this.detailForm.controls.idFinishingResponsible.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.endDate.value) {
        this.detailForm.controls.endDate.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.idAttendanceOccurrence.value) {
        this.detailForm.controls.idAttendanceOccurrence.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.attendanceObservation.value) {
        this.detailForm.controls.attendanceObservation.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.idSolution.value) {
        this.detailForm.controls.idSolution.setErrors({ 'invalid': true });
      } 
      if (!this.detailForm.controls.solutionObservation.value) {
        this.detailForm.controls.solutionObservation.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.escortName.value) {
        this.detailForm.controls.escortName.setErrors({ 'invalid': true });
      }
      if (!this.detailForm.controls.pendencyObservation.value && this.detailForm.controls.pendency.value) {
        this.detailForm.controls.pendencyObservation.setErrors({ 'invalid': true });
      }
    }

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newCalled = new Called();
    newCalled.id = this.idCalled;
    newCalled.idAttendancePlace = this.detailForm.controls.attendancePlace.value.id;
    newCalled.idEquipment = this.detailForm.controls.idEquipment.value;
    newCalled.idInformedOccurrence = this.detailForm.controls.idInformedOccurrence.value;
    newCalled.requesterName = this.detailForm.controls.requesterName.value;
    newCalled.requesterPhone = this.detailForm.controls.requesterPhone.value;
    newCalled.creationDate = this.detailForm.controls.creationDate.value ? this.detailForm.controls.creationDate.value.includes(' ') ? this.detailForm.controls.creationDate.value.replace(' ', 'T') : this.detailForm.controls.creationDate.value : null;
    newCalled.informedObservation = this.detailForm.controls.informedObservation.value;

    if (this.calledStatus > 1) {
      newCalled.thirdPartyName = this.detailForm.controls.thirdPartyName.value;
      newCalled.idAttendanceResponsible = this.detailForm.controls.idAttendanceResponsible.value;
      newCalled.startDate = this.detailForm.controls.startDate.value;
    }

    if (this.calledStatus > 2) {
      newCalled.idFinishingResponsible = this.detailForm.controls.idFinishingResponsible.value;
      newCalled.endDate = this.detailForm.controls.endDate.value;
      newCalled.idAttendanceOccurrence = this.detailForm.controls.idAttendanceOccurrence.value;
      newCalled.attendanceObservation = this.detailForm.controls.attendanceObservation.value;
      newCalled.idSolution = this.detailForm.controls.idSolution.value;
      newCalled.solutionObservation = this.detailForm.controls.solutionObservation.value;
      newCalled.escortName = this.detailForm.controls.escortName.value;
      newCalled.pendencyObservation = this.detailForm.controls.pendencyObservation.value;
      newCalled.inOperation = this.detailForm.controls.inOperation.value;
      newCalled.pendency = this.detailForm.controls.pendency.value;
    }

    this.calledService.createOrUpdate(this.idCalled, newCalled)
      .subscribe(
        data => {
          this.router.navigate(['called/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  searchAttendancePlaces = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.attendancePlaceService.getByAnyArgument(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  selectedAttendancePlace() {

    this.inactiveContract = false;

    if (this.detailForm.controls.attendancePlace.value) {
      this.attendancePlaceService.getAttendancePlace(this.detailForm.controls.attendancePlace.value.id)
        .subscribe(
          data => {

            this.detailForm.controls.contract.setValue(data.contract.contractNumber);

            this.detailForm.controls.equipmentBrand.setValue("");
            this.detailForm.controls.equipmentModel.setValue("");
            this.detailForm.controls.equipmentCode.setValue("");

            //this.detailForm.controls.thirdPartyName.setValue("");
            //this.detailForm.controls.thirdPartyName.clearValidators();
            //this.detailForm.controls.thirdPartyName.updateValueAndValidity({ onlySelf: true });
            //this.detailForm.updateValueAndValidity();

            //this.isThirdParty = data.route.thirdPartyCompany;

            if (data.contract.status == 1) {
              this.detailForm.controls.attendancePlace.setValue(data);
              this.getEquipments(data.id);
            }
            else {
              this.inactiveContract = true;
              this.equipmentList = [];
            }
          },
          error => {
            this.router.navigate(['called/list']);
          });
    }
    else {
      this.detailForm.controls.contract.setValue("");
      this.detailForm.controls.idEquipment.setValue(null);
    }
  }

  getEquipment() {

    if (!this.detailForm.controls.idEquipment.value) {
      this.detailForm.controls.equipmentBrand.setValue("");
      this.detailForm.controls.equipmentModel.setValue("");
      this.detailForm.controls.equipmentCode.setValue("");
      return;
    }

    this.equipmentService.getEquipment(this.detailForm.controls.idEquipment.value)
      .subscribe(
        data => {
          if (data) {
            this.detailForm.controls.equipmentBrand.setValue(data.brand != null ? data.brand.name : null);
            this.detailForm.controls.equipmentModel.setValue(data.model);
            this.detailForm.controls.equipmentCode.setValue(data.qrCode);
          }
          else {
            this.detailForm.controls.equipmentBrand.setValue("");
            this.detailForm.controls.equipmentModel.setValue("");
            this.detailForm.controls.equipmentCode.setValue("");
          }
        },
        error => {
          this.router.navigate(['budgetRequest/list']);
        });
  }

  private getEquipments(idAttendancePlace) {

    this.equipmentService.getByAttendancePlace(idAttendancePlace)
      .subscribe(
        data => {
          this.equipmentList = data;
        },
        error => {
          this.router.navigate(['called/list']);
        });
  }

  private getOccurrences() {

    this.occurrenceService.getOccurrenceList()
      .subscribe(
        data => {
          this.occurrenceList = data;
        },
        error => {
          this.router.navigate(['called/list']);
        });
  }

  private getSolutions() {
    this.solutionService.getSolutionList()
      .subscribe(
        data => {
          this.solutionList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getEmployees() {
    this.employeeService.getEmployeeList()
      .subscribe(
        data => {
          this.employeesList = data;
          //if (this.attendanceResponsible && this.attendanceResponsible.idEmployee) {
          //  var atResponsible = this.employeesList.filter(f => f.id == this.attendanceResponsible.idEmployee)[0];
          //  if (!atResponsible) {
          //    this.employeesList.push(this.attendanceResponsible.employee);
          //  }
          //}

          //if (this.finishingResponsible && this.finishingResponsible.idEmployee) {
          //  var fiResponsible = this.employeesList.filter(f => f.id == this.finishingResponsible.idEmployee)[0];
          //  if (!fiResponsible) {
          //    this.employeesList.push(this.finishingResponsible.employee);
          //  }
          //}
        },
        error => {
          this.error = error;
        });
  }

}
