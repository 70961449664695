import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';

import { User } from '../../app/_models/user';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
    public loggedUser: User;
    public isLoginPage: boolean;
    returnUrl: string;
    isExpanded = false;


    constructor(private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService) { }

    ngOnInit() {
        this.router.events.subscribe(val => {
          this.isLoginPage = this.router.url === '/login';
        });

        this.authenticationService.currentUser.subscribe(user => {
            this.loggedUser = user;
        });
        
        //this.route.url.subscribe((url: UrlSegment[]) => this.isLoginPage = url[0].path === 'login')
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
    
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['login']);
    }
}
