import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalFileImportService {

  constructor(private repo: RepositoryService) { }

  importFile(file: any) {
    return this.repo.postWithFile('externalFileImports/import', file)
      .pipe(map((log: any) => {
        return log;
      }));
  }
}
