import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-password-definition',
  templateUrl: './password-definition.component.html',
  styleUrls: ['./password-definition.component.css']
})
export class PasswordDefinitionComponent implements OnInit {
  editForm: FormGroup;
  loading = false;
  submitted = false;
  idUser = 0;
  token: string;

  constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router, private userService: UserService) { }

    ngOnInit() {
        this.editForm = this.formBuilder.group({ password: ['', Validators.required], passwordConfirmation: ['', Validators.required] });

        this.activeRoute.params.subscribe(routeParams => {
            this.idUser = parseInt(routeParams.id);
            this.token = routeParams.token;
        });
    }

    onSubmit() {

        this.submitted = true;

        if (this.editForm.controls.password.value !== this.editForm.controls.passwordConfirmation.value) {
            this.editForm.controls.passwordConfirmation.setErrors({ 'isInvalidConfirmation': true });
        }
        else {
            this.editForm.controls.passwordConfirmation.setErrors(null);
        }

        if (this.editForm.invalid) {
            return;
        }

        this.loading = true;

        this.userService.setPassword(this.idUser, this.editForm.controls.password.value, this.token)
            .subscribe(
                data => {
                    this.router.navigate(['login']);
                },
                error => {
                    this.loading = false;
                });
    }
}
