import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '../../_services/contract.service';
import { ReportService } from '../../_services/report.service';
import { RouteService } from '../../_services/route.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-customer-per-route',
  templateUrl: './customer-per-route.component.html',
  styleUrls: ['./customer-per-route.component.css']
})
export class CustomerPerRouteComponent implements OnInit {
  customerList: any[];
  routeList: any[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  loadingExport = false;
  statusList: any[];

  constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router, private reportService: ReportService, private routeService: RouteService, private contractService: ContractService) { }

  ngOnInit() {

    this.getContractStatus();
    this.getRoutes();

    this.searchForm = this.formBuilder.group({ idRoute: ['', Validators.required], contract: [''] });

    this.get(this.page, this.searchForm.controls.idRoute.value, this.searchForm.controls.contract.value)
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.idRoute.value, this.searchForm.controls.contract.value)
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.idRoute.value, this.searchForm.controls.contract.value)
  }

  private get(page: number, idRoute: number, contract: number) {
    this.submitted = true;

    this.loading = true;
    this.reportService.getCustomerPerRoute(page, idRoute, contract)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.customerList = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  private getRoutes() {
    this.routeService.getRoutesActives()
      .subscribe(
        data => {
          this.routeList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getContractStatus() {
    this.contractService.getContractStatus()
      .subscribe(
        data => {
          this.statusList = data;
        },
        error => {
          this.error = error;
        });
  }

  exportCustomerPerRoute() {

    if (!this.searchForm.controls.idRoute.value) {
      return;
    }

    this.reportService.exportCustomerPerRoute(this.searchForm.controls.idRoute.value, this.searchForm.controls.contract.value)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'relatorio-cliente-por-rota.xlsx');
        });
  }
}
