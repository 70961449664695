import { AttendancePlace } from "./attendance-place";
import { Brand } from "./brand";

export class Equipment {
  id: number;
  idExternal: number;
  name: string;
  model: string;
  stopNumber: number;
  capacity: number;
  numberPassenger: number;
  serialNumber: string;
  numberPlate: string;
  hasAttendance: boolean;
  tagCode: string;
  status: number;
  idAttendancePlace: number;
  attendancePlace: AttendancePlace;
  qrCode: number;
  brand: Brand;
  idBrand: number;
  idBrandExternal: number;
}
