import { Component, OnInit } from '@angular/core';
import { ChecklistService } from '../../_services/checklist.service';
import { FormGroup, FormBuilder, RequiredValidator, Validators, FormGroupDirective } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Checklist } from '../../_models/checklist';
import { ChecklistItem } from '../../_models/checklist-item';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, first, switchMap, catchError } from 'rxjs/operators';
import { ChecklistCategory } from '../../_models/checklist-category';
import { ModalDefaultService } from '../../modal-default/modal-default.service';
import { ChecklistItemInput } from '../../_models/checklist-item-input';
import { ChecklistCategoryItem } from '../../_models/checklist-category-item';
import { ContractService } from '../../_services/contract.service';

@Component({
  selector: 'app-checklist-edit',
  templateUrl: './checklist-edit.component.html',
  styleUrls: ['./checklist-edit.component.css']
})
export class ChecklistEditComponent implements OnInit {

  checklistCategory: ChecklistCategory;
  checklist: Checklist;
  editForm: FormGroup;
  idChecklist = 0;
  submitted = false;
  error = '';
  editMode = false;
  itemError = false;
  categoryName = null;
  checklistItems: ChecklistItem[];
  categoryItemsGrouped: ChecklistCategoryItem[];
  categoryAlreadyExists = false;
  frequencyList: any[];

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private checklistService: ChecklistService, private modalService: ModalDefaultService, private contractService: ContractService) {
  }

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      checklistName: ['', Validators.required], checklistDescription: [''], checklistItemDescription: [''], checklistItemCategory: [''], checklistItemFrequency: [null], externalCode: [null, Validators.maxLength(5)]
    });

    this.checklistItems = [];

    this.activeRoute.params.subscribe(routeParams => {
      this.idChecklist = parseInt(routeParams.id);
    });

    this.getFrequencies();

    if (this.idChecklist != null && this.idChecklist != 0) {

      this.editForm.disable();

      this.checklistService.getChecklist(this.idChecklist)
        .subscribe(
          data => {
            this.editForm.setValue({ checklistName: data.name, checklistDescription: data.description, externalCode: data.externalCode, checklistItemDescription: null, checklistItemCategory: null, checklistItemFrequency: null });
            this.checklistItems = data.checklistItems != null ? data.checklistItems : [];
            this.checklist = data;
            this.groupItems();
          },
          error => {
            this.router.navigate(['checklist']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  ngOnSubmit() {

    this.submitted = true;

    if (this.editForm.invalid) {
      return;
    }

    this.checklist = new Checklist();
    this.checklist.id = this.idChecklist == 0 ? null : this.idChecklist;
    this.checklist.name = this.editForm.controls.checklistName.value;
    this.checklist.description = this.editForm.controls.checklistDescription.value;
    this.checklist.externalCode = this.editForm.controls.externalCode.value;

    this.checklistService.createOrUpdate(this.checklist)
      .subscribe(
        data => {
          if (this.idChecklist == null || this.idChecklist == 0) {
            this.router.navigate(['checklist/' + data.id]);
          }
        },
        error => {
          this.error = error;
        });
  }

  addChecklistItem() {

    this.itemError = false;

    if (!(this.editForm.controls.checklistItemCategory.value != null && this.editForm.controls.checklistItemDescription.value)) {
      this.itemError = true;
      return;
    }

    var checklistItemInput = new ChecklistItemInput();
    checklistItemInput.idChecklist = this.idChecklist;
    checklistItemInput.description = this.editForm.controls.checklistItemDescription.value;
    checklistItemInput.frequency = this.editForm.controls.checklistItemFrequency.value;
    checklistItemInput.ChecklistCategory = this.editForm.controls.checklistItemCategory.value;

    this.checklistService.createOrUpdateItem(null, checklistItemInput)
      .subscribe(
        data => {
          this.checklistItems.push(data);
          this.groupItems();
        },
        error => {
          this.ngOnInit();
        });

    this.editForm.controls.checklistItemCategory.setValue("");
    this.editForm.controls.checklistItemDescription.setValue("");
    this.editForm.controls.checklistItemFrequency.setValue("");
  }

  deleteChecklistItem(idItem: number) {

    this.checklistService.deleteItemCategory(idItem)
      .subscribe(
        data => {
          this.ngOnInit();
        },
        error => {
        });
  }

  searchCategory = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.checklistService.getCategoriesByName(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  inputFormatCategoryValue(value: any) {
    if (value.name)
      return value.name
    return value;
  }

  resultFormatCategoryValue(value: any) {
    return value.name;
  }

  categoryValidation(content, categoryName: string) {

    this.categoryName = null;

    if (!this.editForm.controls.checklistItemCategory.value && categoryName.length >= 3) {

      this.categoryName = categoryName;

      this.checklistService.getCategoryByName(this.categoryName)
        .subscribe(
          data => {
            if (data) {
              this.categoryAlreadyExists = true;
              this.editForm.controls.checklistItemCategory.setValue(null);
            }
            else {
              this.categoryAlreadyExists = false;
            }
          });

      this.modalService.openDefault(content, null);
    }
  }

  onSubmitChecklistCategory() {

    if (this.categoryName) {

      this.checklistCategory =
      {
        name: this.categoryName,
        id: null
      }

      this.checklistService.createCategory(this.checklistCategory)
        .pipe(first())
        .subscribe(
          data => {
            this.editForm.controls.checklistItemCategory.setValue(data);
            this.categoryName = null;
            this.modalService.closeDefault();
          },
          error => {
            this.error = error;
          });
    }
  }

  setEditMode() {
    this.editMode = true;
    this.editForm.enable();
  }

  private getFrequencies() {
    this.contractService.getAttendanceFactors()
      .subscribe(
        data => {
          this.frequencyList = data;
        },
        error => {
          this.error = error;
        });
  }

  groupItems() {

    this.categoryItemsGrouped = [];

    var categories = this.checklistItems.map(({ checklistCategory }) => checklistCategory).filter((checklistCategory, i, arr) => arr.findIndex(c => c.id == checklistCategory.id) == i);

    for (var i = 0; i < categories.length; i++) {

      var group = new ChecklistCategoryItem();
      group.category = categories[i];
      group.items = [];

      this.categoryItemsGrouped.push(group);
    }

    for (var j = 0; j < this.categoryItemsGrouped.length; j++) {
      for (var i = 0; i < this.checklistItems.length; i++) {
        if (this.categoryItemsGrouped[j].category.id == this.checklistItems[i].checklistCategory.id) {
          this.categoryItemsGrouped[j].items.push(this.checklistItems[i]);
        }
      }
    }
  };
}
