import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IntegrationSyncService } from '../_services/integration-sync.service';

@Component({
  selector: 'app-integration-sync',
  templateUrl: './integration-sync.component.html',
  styleUrls: ['./integration-sync.component.css']
})
export class IntegrationSyncComponent implements OnInit {

  loading = false;
  messages = null;

  constructor(private integrationService: IntegrationSyncService) { }

  ngOnInit() {

  }

  onClick() {

    this.loading = true;

    this.integrationService.syncAll()
      .subscribe(
        data => {
          this.messages = data;
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }
}
