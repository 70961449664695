import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BrandService } from '../../_services/brand.service';
import { Brand } from '../../_models/brand';

@Component({
  selector: 'app-brand-edit',
  templateUrl: './brand-detail.component.html',
  styleUrls: ['./brand-detail.component.css']
})
export class BrandDetailComponent implements OnInit {
  detailForm: FormGroup;
  idBrand = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private brandService: BrandService) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({ brandName: ['', Validators.required], idExternal: [''] });

    this.activeRoute.params.subscribe(routeParams => {
      this.idBrand = parseInt(routeParams.id);
    });

    if (this.idBrand > 0) {

      this.detailForm.disable();

      this.brandService.getBrand(this.idBrand)
        .subscribe(
          data => {
            this.detailForm.setValue({
              brandName: data.name, idExternal: data.idExternal
            });
          },
          error => {
            this.router.navigate(['route']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newBrand = new Brand();
    newBrand.id = this.idBrand;
    newBrand.idExternal = parseInt(this.detailForm.controls.idExternal.value);
    newBrand.name = this.detailForm.controls.brandName.value;

    this.brandService.createOrUpdate(this.idBrand, newBrand)
      .subscribe(
        data => {
          this.router.navigate(['brand/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
