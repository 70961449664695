import { Component, OnInit } from '@angular/core';
import { AttendancePlace } from '../../_models/attendance-place';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../_models/customer';
import { CustomerService } from '../../_services/customer.service';

@Component({
  selector: 'app-attendance-place-list',
  templateUrl: './attendance-place-list.component.html',
  styleUrls: ['./attendance-place-list.component.css']
})
export class AttendancePlaceListComponent implements OnInit {
    attendancePlaces: AttendancePlace[];
    searchForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    idCustomer = 0;
    idRoute = 0;
    page = 1;
    totalResults = 0;
    resultsPerPage = 0;
    customer: Customer;

    constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private attendancePlaceService: AttendancePlaceService, private customerService: CustomerService, private router: Router) { }

    ngOnInit() {
        this.searchForm = this.formBuilder.group({ attendancePlaceName: [''], maintenanceDayOfMonth: [''], addressName: [''], customerArgument: [''], contractCode: [''], routeName: ['']});
        this.activeRoute.queryParams.subscribe(params => {
            this.idCustomer = params.idCustomer;
            this.idRoute = params.idRoute;
        });

        if (this.idCustomer) {
            this.getCustomer();
        }

        this.getAttendancePlaces(this.page, this.idCustomer, this.idRoute, this.searchForm.controls.attendancePlaceName.value, this.searchForm.controls.maintenanceDayOfMonth.value, this.searchForm.controls.addressName.value, this.searchForm.controls.customerArgument.value, this.searchForm.controls.contractCode.value, this.searchForm.controls.routeName.value )
    }

    onSelectedPage() {
        this.getAttendancePlaces(this.page, this.idCustomer, this.idRoute, this.searchForm.controls.attendancePlaceName.value, this.searchForm.controls.maintenanceDayOfMonth.value, this.searchForm.controls.addressName.value, this.searchForm.controls.customerArgument.value, this.searchForm.controls.contractCode.value, this.searchForm.controls.routeName.value)
    }

    onSubmit() {
        if (this.searchForm.invalid) {
            return;
        }

        this.page = 1;
        this.getAttendancePlaces(this.page, this.idCustomer, this.idRoute, this.searchForm.controls.attendancePlaceName.value, this.searchForm.controls.maintenanceDayOfMonth.value, this.searchForm.controls.addressName.value, this.searchForm.controls.customerArgument.value, this.searchForm.controls.contractCode.value, this.searchForm.controls.routeName.value)
    }

    private getAttendancePlaces(page: number, idCustomer: number, idRoute: number, attendancePlaceName: string, maintenanceDayOfMonth: number, addressName: string, customerArgument: string, contractCode: string, routeName: string) {
        this.submitted = true;

        this.loading = true;
        this.attendancePlaceService.getAttendancePlaces(page, idCustomer, idRoute, attendancePlaceName, maintenanceDayOfMonth, addressName, customerArgument, contractCode, routeName)
            .subscribe(
                data => {
                    this.page = data.pageNumber;
                    this.resultsPerPage = data.resultsPerPage;
                    this.totalResults = data.totalResults;
                    this.attendancePlaces = data.response;
                    this.loading = false;
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    private getCustomer() {
        this.customerService.getCustomer(this.idCustomer)
          .subscribe(
            data => {
              this.customer = data;
            },
            error => {
              this.router.navigate(['customer/list']);
            });
    }
}
