import { Employee } from "./employee";
import { Contact } from "./contact";

export class User {
    id: number;
    login: string;
    name: string;
    cpf: string;
    phoneNumber: string;
    email: string;
    token?: string;
    profile: number;
    permissions: string[];
    employee: Employee;
    contact: Contact;
    idEmployee: number;
    authorizationToken: string;
    idRoute: number;
}
