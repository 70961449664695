import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AttendanceService {

    constructor(private repo: RepositoryService) { }

    getAttendance(id: number) {
        return this.repo.getData('attendances/' + id)
            .pipe(map((attendance: any) => {
                return attendance;
            }));
    }

    getAttendanceToSubscribe(id: number) {
      return this.repo.getData('attendances/subscribe/' + id)
            .pipe(map((attendance: any) => {
                return attendance;
            }));
    }

    getSituation(idEquipment: number, year: number, month: number) {
        return this.repo.getData('attendances/equipment/' + idEquipment + '/year/' + year + '/month/' + month + '/verification')
            .pipe(map((attendanceSituation: any) => {
                return attendanceSituation;
            }));
    }

    executeAction(endpoint: string, isPostAction: boolean, startDate: Date, idEmployee: any) {
        if (isPostAction) {
            return this.repo.post(endpoint, { startDate: startDate, idEmployee: idEmployee })
                .pipe(map((attendance: any) => {
                    return attendance;
                }));
        }
        else {
            return this.repo.getData(endpoint)
                .pipe(map((attendance: any) => {
                    return attendance;
                }));
        }
    }
    
    finishAttendance(idAttendance: number, obj: any) {
        return this.repo.post('attendances/' + idAttendance + '/finish', obj)
            .pipe(map((attendance: any) => {
                return attendance;
            }));
    }

    answerChecklist(idAttendance: number, idChecklistItem: number, answer: any) {
        return this.repo.postWithFile('attendances/' + idAttendance + '/checklistItem/' + idChecklistItem, answer)
            .pipe(map((attendanceSituation: any) => {
                return attendanceSituation;
            }));
    }
  
    saveSignature(idAttendance: number, signatureModel: any) {
        return this.repo.postWithFile('attendances/' + idAttendance + '/signature', signatureModel)
            .pipe(map((attendance: any) => {
                return attendance;
            }));
    }

    getReportFile(idAttendance: number) {
        return this.repo.getDataFile('attendances/' + idAttendance + '/download');
    }
}
