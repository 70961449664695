import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractService } from '../../_services/contract.service';
import { Customer } from '../../_models/customer';
import { Contract } from '../../_models/contract';
import { CustomerService } from '../../_services/customer.service';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { AttendancePlace } from '../../_models/attendance-place';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.css']
})
export class ContractDetailComponent implements OnInit {
    detailForm: FormGroup;
    idContract = 0;
    editMode = false;
    loading = false;
    submitted = false;
    error = '';
    customerList: Customer[];
    attendanceFactors: any[];
    attendancePlaces: AttendancePlace[];
    statusList: any[];

    formatter = (customer: Customer) => customer.businessName;

    constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private contractService: ContractService, private customerService: CustomerService, private attendancePlaceService: AttendancePlaceService) { }

    ngOnInit() {
        this.detailForm = this.formBuilder.group({
            contractNumber: ['', Validators.required], attendanceFactorName: [''], customer: [null, Validators.required], attendanceFactor: [null, Validators.required], endDate: [null], startDate: [null], status: [null, Validators.required], customerName: ['']
        });

        this.activeRoute.params.subscribe(routeParams => {
            this.idContract = parseInt(routeParams.id);
        });

        this.getAttendanceFactors();
        this.getAttendancePlaces();
        this.getContractStatus();

        if (this.idContract > 0) {

            this.detailForm.disable();
          
            this.contractService.getContract(this.idContract)
                .subscribe(
                    data => {
                        this.detailForm.setValue({
                            contractNumber: data.contractNumber, attendanceFactorName: data.attendanceFactorName, customer: data.customer, attendanceFactor: data.attendanceFactor, endDate: data.endDate, startDate: data.startDate, status: data.status,
                            customerName: data.customer.businessName + " - " + data.customer.idExternal
                        });
                    },
                    error => {
                        this.router.navigate(['contract']);
                  });
        }
        else {
            this.setEditMode();
        }
    }
  
    onSubmit() {
      
        this.submitted = true;
      
        if (this.detailForm.invalid) {
            return;
        }

        this.loading = true;
      
        var newContract = new Contract();
        newContract.id = this.idContract;
        newContract.contractNumber = this.detailForm.controls.contractNumber.value;
        newContract.customer = this.detailForm.controls.customer.value;
        newContract.attendanceFactor = this.detailForm.controls.attendanceFactor.value;      
        newContract.status = this.detailForm.controls.status.value;
        newContract.startDate = this.detailForm.controls.startDate.value ? new Date(this.detailForm.controls.startDate.value) : null;
        newContract.endDate = this.detailForm.controls.endDate.value ? new Date(this.detailForm.controls.endDate.value) : null;

        this.contractService.createOrUpdate(this.idContract, newContract)
            .subscribe(
                data => {
                    this.router.navigate(['contract/list']);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    setEditMode() {
        this.editMode = true;
        this.detailForm.enable();
    }
  
    private getAttendanceFactors() {
        this.contractService.getAttendanceFactors()
            .subscribe(
                data => {
                    this.attendanceFactors = data;
                },
                error => {
                    this.error = error;
                });
    }
  
    getCustomers = (text$: Observable<any>) => text$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(term => term.length >= 3),
        switchMap(term =>
            this.customerService.getByArgument(term).pipe(
                catchError(() => {
                    return of([]);
                }))
        )
    );

    private getAttendancePlaces() {
        this.attendancePlaceService.getAttendancePlacesByContract(this.idContract)
            .subscribe(
                data => {
                  this.attendancePlaces = data;
                },
                error => {
                  this.error = error;
                });
    }
  
    private getContractStatus() {
        this.contractService.getContractStatus()
            .subscribe(
                data => {
                    this.statusList = data;
                },
                error => {
                    this.error = error;
                });
    }
}
