import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceService } from '../../_services/attendance.service';
import { ChecklistAttendance } from '../../_models/checklist-attendance';
import { ngfModule, ngf } from "angular-file";
import { UiSwitchModule } from 'ngx-ui-switch';

@Component({
  selector: 'app-checklist-completion',
  templateUrl: './checklist-completion.component.html',
  styleUrls: ['./checklist-completion.component.css']
})
export class ChecklistCompletionComponent implements OnInit {

  attendance = null;
  checklist: ChecklistAttendance;
  idAttendance = 0;
  loading = false;

  accept = '*';

  finalizationNote = null;

  myFormData: FormData = new FormData();

  constructor(private router: Router, private attendanceService: AttendanceService, private activeRoute: ActivatedRoute) { }

  ngOnInit() {

    this.activeRoute.params.subscribe(routeParams => {
      this.idAttendance = parseInt(routeParams.idAttendance);
    });

    this.attendanceService.getAttendance(this.idAttendance)
      .subscribe(
        data => {

          if (data == null || data.status != 2 ) {
            this.router.navigate(['attendance/list']);
          }

          this.attendance = data;
          this.checklist = this.attendance.checklist;
        },
        error => {
          this.router.navigate(['home/0']);
        });
  }

  changeItem(item: any) {
    item.showSave = true;
  }

  saveReply(item: any) {
    this.loading = true;

    this.myFormData = new FormData();

    if (!item.situation) {
      this.myFormData.append("description", item.descriptionVerification ? item.descriptionVerification : "");
      this.myFormData.append("situationOk", item.situation);
      this.myFormData.append("notApplicable", item.notApplicable);

      if (item.answerFile) {
        this.myFormData.append("file", item.answerFile, item.answerFile.name);
      }

      this.attendanceService.answerChecklist(this.idAttendance, item.id, this.myFormData)
        .subscribe(
          data => {
            this.ngOnInit();
            this.loading = false;
          },
          error => {
            this.loading = false;
          });
    }
    else {
      this.myFormData.append("situationOk", item.situation);
      this.myFormData.append("notApplicable", item.notApplicable);

      this.attendanceService.answerChecklist(this.idAttendance, item.id, this.myFormData)
        .subscribe(
          data => {
            this.ngOnInit();
            this.loading = false;
          },
          error => {
            this.loading = false;
          });
    }
  }

  changeSituation(item) {
    item.situation = true;
    item.showSave = true;
  }

  finishAttendance() {

    this.loading = true;

    var checklistItems = [];

    for (var i = 0; i < this.checklist.checklistCategories.length; i++) {
      for (var j = 0; j < this.checklist.checklistCategories[i].checklistItems.length; j++) {
        checklistItems.push(this.checklist.checklistCategories[i].checklistItems[j]);
      }
    }

    if (checklistItems.length > 0) {

      var obj = {
        note: this.finalizationNote,
        checklistItems: checklistItems
      };

      this.attendanceService.finishAttendance(this.idAttendance, obj)
        .subscribe(
          data => {
            this.router.navigate(['attendance/equipment/' + data.equipment.attendancePlace.id + '/year/' + data.year + '/month/' + data.month]);
          },
          error => {
            this.loading = false;
          });
    }
  }
}
