import { Component, OnInit } from '@angular/core';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContractService } from '../../_services/contract.service';
import { RouteService } from '../../_services/route.service';
import { CustomerService } from '../../_services/customer.service';
import { AttendancePlaceInput } from '../../_models/attendance-place-input';
import { Customer } from '../../_models/customer';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-attendance-place-detail',
  templateUrl: './attendance-place-detail.component.html',
  styleUrls: ['./attendance-place-detail.component.css']
})
export class AttendancePlaceDetailComponent implements OnInit {
  detailForm: FormGroup;
  idAttendancePlace = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';
  equipments = [];
  constractList = [];
  customerList = [];
  routeList = [];
  idAddress = null;

  formatter = (customer: Customer) => customer.businessName;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private attendancePlaceService: AttendancePlaceService,
    private contractService: ContractService, private routeService: RouteService, private customerService: CustomerService) { }

  ngOnInit() {
    this.detailForm = this.formBuilder.group({
      placeName: ['', Validators.required], idExternal: [''], customerName: [''], stateName: ['', Validators.required], cityName: ['', Validators.required], streetName: ['', Validators.required], maintenanceWeekdayName: [''], contractNumber: [''], routeName: [''], zipCode: [''],
      thirdPartyCompany: [''], observationAttendance: [''], corporateName: [''], cpfCnpj: [''], customer: [null, Validators.required], idContract: [null, Validators.required], idRoute: [null], maintenanceDayOfMonth: [''], customerIdExternal: ['']
    });

    this.activeRoute.params.subscribe(routeParams => {
      this.idAttendancePlace = parseInt(routeParams.id);
    });

    this.getRoutes();

    if (this.idAttendancePlace > 0) {

      this.detailForm.disable();

      this.attendancePlaceService.getAttendancePlace(this.idAttendancePlace)
        .subscribe(
          data => {

            this.getContracts(data.customer.id);

            this.detailForm.setValue({
              placeName: data.name, idExternal: data.idExternal, customerName: data.customer.businessName, contractNumber: data.contract.contractNumber,
              routeName: data.route != null ? data.route.name : null, stateName: data.address.state, cityName: data.address.city, streetName: data.address.description,
              maintenanceWeekdayName: data.maintenanceWeekdayName, zipCode: data.address.zipCode, thirdPartyCompany: data.route != null ? data.route.thirdPartyCompany ? "Sim" : "Não" : null, observationAttendance: data.observationAttendance,
              corporateName: data.customer.corporateName, cpfCnpj: data.customer.cpfCnpj, customer: data.customer, idRoute: data.route != null ? data.route.id : null, idContract: data.contract ? data.contract.id : null, maintenanceDayOfMonth: data.maintenanceDayOfMonth, customerIdExternal: data.customer.idExternal
            });

            if (data.address != null) {
              this.idAddress == data.address.id;
            }

            this.equipments = data.equipments;
          },
          error => {
            this.router.navigate(['attendancePlace']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newAttendancePlace = new AttendancePlaceInput();
    newAttendancePlace.id = this.idAttendancePlace;
    newAttendancePlace.idExternal = parseInt(this.detailForm.controls.idExternal.value);
    newAttendancePlace.idAddress = this.idAddress;
    newAttendancePlace.name = this.detailForm.controls.placeName.value;
    newAttendancePlace.maintenanceDayOfMonth = this.detailForm.controls.maintenanceDayOfMonth.value == '' ? null : this.detailForm.controls.maintenanceDayOfMonth.value;
    newAttendancePlace.observationAttendance = this.detailForm.controls.observationAttendance.value;
    newAttendancePlace.state = this.detailForm.controls.stateName.value;
    newAttendancePlace.city = this.detailForm.controls.cityName.value;
    newAttendancePlace.description = this.detailForm.controls.streetName.value;
    newAttendancePlace.zipCode = this.detailForm.controls.zipCode.value;
    newAttendancePlace.idCustomer = this.detailForm.controls.customer.value ? this.detailForm.controls.customer.value.id : null;
    newAttendancePlace.idRoute = this.detailForm.controls.idRoute.value ? this.detailForm.controls.idRoute.value : null;
    newAttendancePlace.idContract = this.detailForm.controls.idContract.value;

    this.attendancePlaceService.createOrUpdate(this.idAttendancePlace, newAttendancePlace)
      .subscribe(
        data => {
          this.router.navigate(['attendancePlace/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  private getRoutes() {
    this.routeService.getRoutesActives()
      .subscribe(
        data => {
          this.routeList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getContracts(idCustomer: number) {
    this.contractService.getContractsByCustomer(idCustomer)
      .subscribe(
        data => {
          this.constractList = data;
        },
        error => {
          this.error = error;
        });
  }

  cpfCnpjMask(): string {
    if (this.detailForm.controls.cpfCnpj.value) {
      return this.detailForm.controls.cpfCnpj.value.length < 12 ? '000.000.000-009' : '00.000.000/0000-00';
    }
  }

  zipCodeMask(): string {
    if (this.detailForm.controls.zipCode.value) {
      return '00000-000';
    }
  }

  setEditForm() {
    if (this.detailForm.controls.customer.value) {
      this.detailForm.controls.placeName.setValue(this.detailForm.controls.customer.value.businessName);
      this.detailForm.controls.corporateName.setValue(this.detailForm.controls.customer.value.corporateName);
      this.detailForm.controls.cpfCnpj.setValue(this.detailForm.controls.customer.value.cpfCnpj);
      this.detailForm.controls.customerIdExternal.setValue(this.detailForm.controls.customer.value.idExternal);

      this.detailForm.controls.idContract.setValue(null);

      if (this.detailForm.controls.customer.value.address != null) {
        this.detailForm.controls.stateName.setValue(this.detailForm.controls.customer.value.address.state);
        this.detailForm.controls.cityName.setValue(this.detailForm.controls.customer.value.address.city);
        this.detailForm.controls.streetName.setValue(this.detailForm.controls.customer.value.address.description);
        this.detailForm.controls.zipCode.setValue(this.detailForm.controls.customer.value.address.zipCode);
      }
      else {
        this.detailForm.controls.stateName.setValue(null);
        this.detailForm.controls.cityName.setValue(null);
        this.detailForm.controls.streetName.setValue(null);
        this.detailForm.controls.zipCode.setValue(null);
      }

      this.getContracts(this.detailForm.controls.customer.value.id)
    }
  }

  getCustomers = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.customerService.getByArgument(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );
}
