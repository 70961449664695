import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { CalledService } from '../../_services/called.service';
import { ReportService } from '../../_services/report.service';

@Component({
  selector: 'app-called-critical',
  templateUrl: './called-critical.component.html',
  styleUrls: ['./called-critical.component.css']
})
export class CalledCriticalComponent implements OnInit {
  calleds: any[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  loadingExport = false;

  statusList: any[];
  routeList: any[];

  constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router, private reportService: ReportService, private calledService: CalledService) { }

  ngOnInit() {

    this.searchForm = this.formBuilder.group({ fromDate: [null, Validators.required], toDate: [null, Validators.required], quantity: [3, Validators.required], });

    this.get(this.page, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.quantity.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.quantity.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.quantity.value);
  }

  private get(page: number, fromDate: Date, toDate: Date, quantity: number) {

    this.submitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    this.reportService.getCalledCritical(page, fromDate, toDate, quantity)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.calleds = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  export() {

    this.reportService.exportCalledCritical(this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.quantity.value)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'relatorio-chamados-criticos.xlsx');
        });
  }
}
