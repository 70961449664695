import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';
import { ReasonRequest } from '../_models/reason-request';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class ReasonRequestService {

  constructor(private repo: RepositoryService) { }

  getReasonRequests(page: number, argument: string) {
    let urlParams = '?page=' + page;

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('reasonRequests' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<ReasonRequest>) => {
        return paginatedResponse;
      }));
  }

  getReasonRequest(id: number) {
    return this.repo.getData('reasonRequests/' + id)
      .pipe(map((reasonRequest: ReasonRequest) => {
        return reasonRequest;
      }));
  }

  getReasonRequestList() {
    return this.repo.getData('reasonRequests/all')
      .pipe(map((reasonRequests: ReasonRequest[]) => {
        return reasonRequests;
      }));
  }

  createOrUpdate(id: number, reasonRequest: ReasonRequest) {
    if (id && id > 0) {
      return this.repo.put('reasonRequests/' + id, reasonRequest);
    }
    else {
      reasonRequest.id = null;
      return this.repo.post('reasonRequests', reasonRequest);
    }
  }
}
