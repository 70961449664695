import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { CalledService } from '../../_services/called.service';
import { ReportService } from '../../_services/report.service';
import { RouteService } from '../../_services/route.service';

@Component({
  selector: 'app-called-per-route-general',
  templateUrl: './called-per-route-general.component.html',
  styleUrls: ['./called-per-route-general.component.css']
})
export class CalledPerRouteGeneralComponent implements OnInit {
  calleds: any[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  loadingExport = false;

  statusList: any[];
  routeList: any[];

  constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router, private reportService: ReportService, private routeService: RouteService, private calledService: CalledService) { }

  ngOnInit() {

    this.getRoutes();
    this.getStatus();

    this.searchForm = this.formBuilder.group({ idRoute: [''], fromDate: [null], toDate: [null], status: [''] });

    this.get(this.page, this.searchForm.controls.idRoute.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.idRoute.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value);
  }

  onSubmit() {
    this.page = 1;
    this.get(this.page, this.searchForm.controls.idRoute.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value);
  }

  private get(page: number, idRoute: any, fromDate: Date, toDate: Date, status: number) {
    this.submitted = true;

    this.loading = true;

    this.reportService.getCalledPerRouteGeneral(page, idRoute, fromDate, toDate, status)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.calleds = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  private getRoutes() {
    this.routeService.getRoutesActives()
      .subscribe(
        data => {
          this.routeList = data;
        },
        error => {
          this.error = error;
        });
  }

  private getStatus() {
    this.calledService.getStatus()
      .subscribe(
        data => {
          this.statusList = data;
        },
        error => {
          this.error = error;
        });
  }

  export() {

    this.reportService.exportCalledPerRouteGeneral(this.searchForm.controls.idRoute.value.id, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'relatorio-chamado-geral-por-rotas.xlsx');
        });
  }
}
