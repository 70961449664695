import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { QRCodeModule } from 'angularx-qrcode';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { ngfModule } from 'angular-file';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DatePipe } from '@angular/common';

import { CustomAdapter, CustomDateParserFormatter } from './_adapters/datepicker-adapter';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { ContractListComponent } from './contract/contract-list/contract-list.component';
import { ContractDetailComponent } from './contract/contract-detail/contract-detail.component';
import { RouteDetailComponent } from './route/route-detail/route-detail.component';
import { RouteListComponent } from './route/route-list/route-list.component';
import { AttendancePlaceListComponent } from './attendance-place/attendance-place-list/attendance-place-list.component';
import { AttendancePlaceDetailComponent } from './attendance-place/attendance-place-detail/attendance-place-detail.component';
import { EquipmentDetailComponent } from './equipment/equipment-detail/equipment-detail.component';
import { EquipmentListComponent } from './equipment/equipment-list/equipment-list.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { EmployeeDetailComponent } from './employee/employee-detail/employee-detail.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { CodeReaderComponent } from './code-reader/code-reader.component';
import { PasswordDefinitionComponent } from './password-definition/password-definition.component';
import { ChecklistListComponent } from './checklist/checklist-list/checklist-list.component';
import { ChecklistEditComponent } from './checklist/checklist-edit/checklist-edit.component';
import { ToastGlobalComponent } from './toast-global/toast-global.component';
import { ToastsContainerComponent } from './toast-global/toasts-container.component';
import { IntegrationSyncComponent } from './integration-sync/integration-sync.component';
import { AttendanceListComponent } from './attendance/attendance-list/attendance-list.component';
import { AttendanceEquipmentListComponent } from './attendance/equipment-list/equipment-list.component';
import { ContactComponent } from './attendance-place/contact/contact.component';
import { ChecklistCompletionComponent } from './attendance/checklist-completion/checklist-completion.component';
import { SignatureComponent } from './attendance/signature/signature.component';
import { EquipmentResumeComponent } from './report/equipment-resume/equipment-resume.component';

import { AuthGuardService } from './_services/auth-guard.service';
import { AuthenticationService } from './_services/authentication.service';
import { RequestInterceptorService } from './_services/request-interceptor.service';
import { RepositoryService } from './_services/repository.service';

import { HasPermissionDirective } from './_directives/has-permission.directive';
import { AttendancePerPlaceComponent } from './report/attendance-per-place/attendance-per-place.component';
import { AttendancePerCustomerComponent } from './report/attendance-per-customer/attendance-per-customer.component';
import { BrandListComponent } from './brand/brand-list/brand-list.component';
import { BrandDetailComponent } from './brand/brand-detail/brand-detail.component';
import { CustomerPerRouteComponent } from './report/customer-per-route/customer-per-route.component';
import { EquipmentPerRouteComponent } from './report/equipment-per-route/equipment-per-route.component';
import { AttendanceGeneralComponent } from './report/attendance-general/attendance-general.component';
import { ExternalFileImportComponent } from './external-file-import/external-file-import.component';
import { AuthorizationLoginComponent } from './authorization-login/authorization-login.component';
import { ReasonRequestListComponent } from './reason-request/reason-request-list/reason-request-list.component';
import { ReasonRequestDetailComponent } from './reason-request/reason-request-detail/reason-request-detail.component';
import { BudgetRequestListComponent } from './budget-request/budget-request-list/budget-request-list.component';
import { BudgetRequestDetailsComponent } from './budget-request/budget-request-details/budget-request-details.component';
import { OccurrenceListComponent } from './occurrence/occurrence-list/occurrence-list.component';
import { OccurrenceDetailComponent } from './occurrence/occurrence-detail/occurrence-detail.component';
import { CalledListComponent } from './called/called-list/called-list.component';
import { CalledDetailComponent } from './called/called-detail/called-detail.component';
import { SolutionListComponent } from './solution/solution-list/solution-list.component';
import { SolutionDetailComponent } from './solution/solution-detail/solution-detail.component';
import { CalledPerPlaceComponent } from './report/called-per-place/called-per-place.component';
import { CalledPerRouteComponent } from './report/called-per-route/called-per-route.component';
import { CalledPerRouteGeneralComponent } from './report/called-per-route-general/called-per-route-general.component';
import { CalledCriticalComponent } from './report/called-critical/called-critical.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavMenuComponent,
        HomeComponent,
        CustomerListComponent,
        CustomerDetailComponent,
        ContractListComponent,
        ContractDetailComponent,
        RouteDetailComponent,
        RouteListComponent,
        AttendancePlaceListComponent,
        AttendancePlaceDetailComponent,
        EquipmentDetailComponent,
        EquipmentListComponent,
        EmployeeListComponent,
        EmployeeDetailComponent,
        UserListComponent,
        UserEditComponent,
        CodeReaderComponent,
        PasswordDefinitionComponent,
        ChecklistListComponent,
        ChecklistEditComponent,
        ToastGlobalComponent,
        ToastsContainerComponent,
        IntegrationSyncComponent,
        AttendanceListComponent,
        AttendanceEquipmentListComponent,
        ContactComponent,
        ChecklistCompletionComponent,
        SignatureComponent,
        HasPermissionDirective,
        EquipmentResumeComponent,
        AttendancePerPlaceComponent,
        AttendancePerCustomerComponent,
        BrandListComponent,
        BrandDetailComponent,
        CustomerPerRouteComponent,
        EquipmentPerRouteComponent,
        AttendanceGeneralComponent,
        ExternalFileImportComponent,
        AuthorizationLoginComponent,
        ReasonRequestListComponent,
        ReasonRequestDetailComponent,
        BudgetRequestListComponent,
        BudgetRequestDetailsComponent,
        OccurrenceListComponent,
        OccurrenceDetailComponent,
        CalledListComponent,
        CalledDetailComponent,
        SolutionListComponent,
        SolutionDetailComponent,
        CalledPerPlaceComponent,
        CalledPerRouteComponent,
        CalledPerRouteGeneralComponent,
        CalledCriticalComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgxMaskModule.forRoot(options),
        QRCodeModule,
        ZXingScannerModule,
        SignaturePadModule,
        ngfModule,
        UiSwitchModule,
        RouterModule.forRoot([
            { path: '', redirectTo: 'home/0', pathMatch: 'full' },
            { path: 'home/:step', component: HomeComponent, pathMatch: 'full' },
            { path: 'codeReader/:redirectionCode', component: CodeReaderComponent },
            { path: 'equipmentResume/:qrCode', component: EquipmentResumeComponent, canActivate: [AuthGuardService] },
            { path: 'user/list', component: UserListComponent, canActivate: [AuthGuardService] },
            { path: 'user/:id', component: UserEditComponent, canActivate: [AuthGuardService] },
            { path: 'customer/list', component: CustomerListComponent, canActivate: [AuthGuardService] },
            { path: 'customer/:id', component: CustomerDetailComponent, canActivate: [AuthGuardService] },
            { path: 'contract/list', component: ContractListComponent, canActivate: [AuthGuardService] },
            { path: 'contract/:id', component: ContractDetailComponent, canActivate: [AuthGuardService] },
            { path: 'route/list', component: RouteListComponent, canActivate: [AuthGuardService] },
            { path: 'route/:id', component: RouteDetailComponent, canActivate: [AuthGuardService] },
            { path: 'attendancePlace/list', component: AttendancePlaceListComponent, canActivate: [AuthGuardService] },
            { path: 'attendancePlace/:id', component: AttendancePlaceDetailComponent, canActivate: [AuthGuardService] },
            { path: 'attendancePlace/contact/:idAttendancePlace', component: ContactComponent, canActivate: [AuthGuardService] },
            { path: 'equipment/list', component: EquipmentListComponent, canActivate: [AuthGuardService] },
            { path: 'equipment/:id/:idAttendancePlace', component: EquipmentDetailComponent, canActivate: [AuthGuardService] },
            { path: 'employee/list', component: EmployeeListComponent, canActivate: [AuthGuardService] },
            { path: 'employee/:id', component: EmployeeDetailComponent, canActivate: [AuthGuardService] },
            { path: 'checklist/list', component: ChecklistListComponent, canActivate: [AuthGuardService] },
            { path: 'checklist/:id', component: ChecklistEditComponent, canActivate: [AuthGuardService] },
            { path: 'attendance/list', component: AttendanceListComponent, canActivate: [AuthGuardService] },
            { path: 'attendance/reportByPlace', component: AttendancePerPlaceComponent, canActivate: [AuthGuardService] },
            { path: 'attendance/equipment/:idAttendancePlace/year/:year/month/:month', component: AttendanceEquipmentListComponent, canActivate: [AuthGuardService] },
            { path: 'attendance/checklistCompletion/:idAttendance', component: ChecklistCompletionComponent, canActivate: [AuthGuardService] },
            { path: 'attendance/signature/:idAttendance', component: SignatureComponent, canActivate: [AuthGuardService] },
            { path: 'integrationSync', component: IntegrationSyncComponent, canActivate: [AuthGuardService] },            
            { path: 'brand/list', component: BrandListComponent, canActivate: [AuthGuardService] },
            { path: 'brand/:id', component: BrandDetailComponent, canActivate: [AuthGuardService] },
            { path: 'report/customerPerRoute', component: CustomerPerRouteComponent, canActivate: [AuthGuardService] },
            { path: 'report/attendancePerCustomer', component: AttendancePerCustomerComponent, canActivate: [AuthGuardService] },
            { path: 'report/equipmentPerRoute', component: EquipmentPerRouteComponent, canActivate: [AuthGuardService] },
            { path: 'report/attendanceGeneral', component: AttendanceGeneralComponent, canActivate: [AuthGuardService] },
            { path: 'report/calledPerPlace', component: CalledPerPlaceComponent, canActivate: [AuthGuardService] },
            { path: 'report/calledPerRouteGeneral', component: CalledPerRouteGeneralComponent, canActivate: [AuthGuardService] },
            { path: 'report/calledPerRoute', component: CalledPerRouteComponent, canActivate: [AuthGuardService] },
            { path: 'report/calledCritical', component: CalledCriticalComponent, canActivate: [AuthGuardService] },
            { path: 'externalFileImport', component: ExternalFileImportComponent, canActivate: [AuthGuardService] },
            { path: 'passwordDefinition/user/:id/token/:token', component: PasswordDefinitionComponent },
            { path: 'authorizationLogin/:id/token/:token/authorization/:authorization', component: AuthorizationLoginComponent },
            { path: 'reasonRequest/list', component: ReasonRequestListComponent, canActivate: [AuthGuardService] },
            { path: 'reasonRequest/:id', component: ReasonRequestDetailComponent, canActivate: [AuthGuardService] },
            { path: 'budgetRequest/list', component: BudgetRequestListComponent, canActivate: [AuthGuardService] },
            { path: 'budgetRequest/:id', component: BudgetRequestDetailsComponent, canActivate: [AuthGuardService] },            
            { path: 'occurrence/list', component: OccurrenceListComponent, canActivate: [AuthGuardService] },
            { path: 'occurrence/:id', component: OccurrenceDetailComponent, canActivate: [AuthGuardService] },
            { path: 'called/list', component: CalledListComponent, canActivate: [AuthGuardService] },
            { path: 'called/:id', component: CalledDetailComponent, canActivate: [AuthGuardService] },
            { path: 'solution/list', component: SolutionListComponent, canActivate: [AuthGuardService] },
            { path: 'solution/:id', component: SolutionDetailComponent, canActivate: [AuthGuardService] },
            { path: 'login', component: LoginComponent },
            // otherwise redirect to home
            { path: '**', redirectTo: 'home/0' }
        ], { onSameUrlNavigation: 'reload' })
    ],
    providers: [
        RepositoryService,
        RequestInterceptorService,
        AuthenticationService,
        AuthGuardService,
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true },
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
