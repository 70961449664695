import { Component, OnInit } from '@angular/core';
import { RouteService } from '../../_services/route.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RouteModel } from '../../_models/route-model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-route-list',
  templateUrl: './route-list.component.html',
  styleUrls: ['./route-list.component.css']
})
export class RouteListComponent implements OnInit {
  routes: RouteModel[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  idEmployee = 0;

  constructor(private formBuilder: FormBuilder, private routeService: RouteService, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({ routeName: [''], thirdPartyCompany: [false], employeeName: [''] });

    this.activeRoute.queryParams.subscribe(params => {
      this.idEmployee = params.idEmployee;
    });

    this.getRoutes(this.page, this.idEmployee, this.searchForm.controls.routeName.value, this.searchForm.controls.thirdPartyCompany.value, this.searchForm.controls.employeeName.value)
  }

  onSelectedPage() {
    this.getRoutes(this.page, this.idEmployee, this.searchForm.controls.routeName.value, this.searchForm.controls.thirdPartyCompany.value, this.searchForm.controls.employeeName.value)
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.getRoutes(this.page, this.idEmployee, this.searchForm.controls.routeName.value, this.searchForm.controls.thirdPartyCompany.value, this.searchForm.controls.employeeName.value)
  }

  private getRoutes(page: number, idEmployee: number, routeName: string, thirdPartyCompany: boolean, employeeName: string) {
    this.submitted = true;

    this.loading = true;
    this.routeService.getRoutes(page, idEmployee, routeName, thirdPartyCompany, employeeName)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.routes = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

}
