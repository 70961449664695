import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../../_services/contact.service';
import { Contact } from '../../_models/contact';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
    editForm: FormGroup;
    contact: Contact;
    contacts: Contact[];
    contactList: Contact[];
    selectedContact = null;
    submitted = false;
    idAttendancePlace = 0;
    error = '';
    editMode = false;
    warningTypes = [];
    phoneError = false;

    constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private contactService: ContactService) { }

    ngOnInit() {

        this.editForm = this.formBuilder.group({
          name: ['', Validators.required], document: ['', Validators.required], email: ['', Validators.email], phone: [''], warningType: ['']
        });

        this.activeRoute.params.subscribe(routeParams => {
            this.idAttendancePlace = parseInt(routeParams.idAttendancePlace);
        });

        this.getByAttendancePlace();
        this.getSuggestedContacts();
        this.getWarningTypes();
    }

    onSubmit() {

        this.submitted = true;

        if (this.editForm.invalid) {
           return;
        }

        this.contact = <Contact>{};

        this.contact.name = this.editForm.controls.name.value;
        this.contact.document = this.editForm.controls.document.value;
        this.contact.email = this.editForm.controls.email.value;
        this.contact.phone = this.editForm.controls.phone.value;
        this.contact.warningType = parseInt(this.editForm.controls.warningType.value);

        this.contactService.createOrUpdate(this.idAttendancePlace, this.contact)
            .subscribe(
                data => {
                    if (!this.contacts) {
                        this.contacts = [];
                    }
                    
                    this.contacts.push(data);
                    this.editForm.reset();
                    this.submitted = false;
                },
                error => {
                    this.error = error;
                });
    }

    addContact() {
        if (this.selectedContact) {
            this.contactService.addContact(this.selectedContact.id, this.idAttendancePlace)
                .subscribe(
                    data => {
                        if (!this.contacts) {
                            this.contacts = [];
                        }

                        this.contacts.push(data);

                        for (var i = 0; i < this.contactList.length; i++) {
                            if (this.contactList[i].id == data.id) {
                                this.contactList.splice(i, 1);
                                break;
                            }
                        }

                        this.selectedContact = null;
                    },
                    error => {
                        this.error = error;
                    });
        }
    }

    delete(idContact) {
      this.contactService.deleteContactAttendancePlace(idContact, this.idAttendancePlace)
        .subscribe(() =>
          this.getByAttendancePlace()
        );
    }

    setEditMode() {
        this.editMode = true;
    }

    foneValidation() {
        if (this.editForm.controls.phone && this.editForm.controls.phone.value.length < 10) {
            this.phoneError = true;
        } else {
            this.phoneError = false;
        }
    }

    private getByAttendancePlace() {
        this.contactService.getByAttendancePlace(this.idAttendancePlace)
            .subscribe(
                data => {
                    this.contacts = data;
                },
                error => {
                    this.error = error;
                });
    }

    private getSuggestedContacts() {
        this.contactService.getSuggestedByAttendancePlace(this.idAttendancePlace)
            .subscribe(
                data => {
                    this.contactList = data;
                },
                error => {
                    this.error = error;
                });
    }
    
    private getWarningTypes() {
        this.contactService.getWarningTypes()
            .subscribe(
                data => {
                    this.warningTypes = data;
                },
                error => {
                    this.error = error;
                });
    }
}
