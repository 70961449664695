import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { map } from 'rxjs/operators';
import { Contact } from '../_models/contact';

@Injectable({
    providedIn: 'root'
})

export class ContactService {

    constructor(private repo: RepositoryService) { }

    getByAttendancePlace(idAttendancePlace: number) {
        return this.repo.getData('contacts/attendancePlace/' + idAttendancePlace)
            .pipe(map((contacts: Contact[]) => {
                return contacts;
            }));
    }

    getSuggestedByAttendancePlace(idAttendancePlace: number) {
        return this.repo.getData('contacts/suggested/attendancePlace/' + idAttendancePlace)
            .pipe(map((contacts: Contact[]) => {
                return contacts;
            }));
    }

    createOrUpdate(idAttendancePlace: number, contact: Contact) {

        return this.repo.post('contacts/attendancePlace/' + idAttendancePlace, contact)
            .pipe(map((contact: any) => {
                return contact.data as Contact
            }));
    }

    addContact(idContact: number, idAttendancePlace: number) {

        return this.repo.post('contacts/' + idContact + '/attendancePlace', idAttendancePlace)
            .pipe(map((contact: any) => {
                return contact.data as Contact;
            }));
    }
  
    deleteContactAttendancePlace(idContact: number, idAttendancePlace: number) {

        return this.repo.delete('contacts/' + idContact + '/attendancePlace/' + idAttendancePlace);
    }
  
    getWarningTypes() {
      return this.repo.getData('contacts/warningTypes')
            .pipe(map((warningTypes: any[]) => {
                return warningTypes;
            }));
    }
      
    getContacts() {
      return this.repo.getData('contacts')
            .pipe(map((contacts: any[]) => {
                return contacts;
            }));
    }
    
    getContactsByName(name: string) {
      return this.repo.getData('contacts/name/' + name)
            .pipe(map((contacts: any[]) => {
                return contacts;
            }));
    }   
}
