import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Called } from '../_models/called';
import { PaginatedResponse } from '../_models/paginated-response';
import { RepositoryService } from './repository.service';

@Injectable({
  providedIn: 'root'
})
export class CalledService {

  constructor(private repo: RepositoryService) { }

  getCalleds(page: number, customer: string, attendancePlace: string, idRoute: string, statusList: any[], fromDate: Date, toDate: Date, calledNumber: string) {
    let urlParams = '?page=' + page;

    if (fromDate) {
      urlParams += '&fromDate=' + fromDate;
    }

    if (toDate) {
      urlParams += '&toDate=' + toDate;
    }

    if (customer) {
      urlParams += '&customer=' + customer;
    }

    if (attendancePlace) {
      urlParams += '&attendancePlace=' + attendancePlace;
    }

    if (idRoute) {
      urlParams += '&idRoute=' + idRoute;
    }

    if (calledNumber) {
      urlParams += '&calledNumber=' + calledNumber;
    }

    return this.repo.put('calleds' + urlParams, statusList)
      .pipe(map((paginatedResponse: PaginatedResponse<Called>) => {
        return paginatedResponse;
      }));
  }

  getCalled(id: number) {
    return this.repo.getData('calleds/' + id)
      .pipe(map((called: Called) => {
        return called;
      }));
  }

  getCalledList() {
    return this.repo.getData('calleds/all')
      .pipe(map((calleds: Called[]) => {
        return calleds;
      }));
  }

  createOrUpdate(id: number, called: Called) {
    if (id && id > 0) {
      return this.repo.put('calleds/' + id, called)
        .pipe(map((response: any) => {
          return response.data as Called;
        }));
    }
    else {
      called.id = null;
      return this.repo.post('calleds', called)
        .pipe(map((response: any) => {
          return response.data as Called;
        }));
    }
  }

  getStatus() {
    return this.repo.getData('calleds/status')
      .pipe(map((statusList: any[]) => {
        return statusList;
      }));
  }

  cancelCalled(id: number, observation: string) {
    return this.repo.put('calleds/cancel/' + id, { description: observation });
  }

  startCalled(id: number) {
    return this.repo.put('calleds/start/' + id, null);
  }

  finalizeCalled(id: number, called: Called) {
    return this.repo.put('calleds/finalize/' + id, called)
  }
}
