import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDefaultService } from '../../modal-default/modal-default.service';
import { BudgetRequest } from '../../_models/budget-request';
import { BudgetRequestService } from '../../_services/budget-request.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-budget-request-list',
  templateUrl: './budget-request-list.component.html',
  styleUrls: ['./budget-request-list.component.css']
})
export class BudgetRequestListComponent implements OnInit {

  budgetRequests: BudgetRequest[];
  searchForm: FormGroup;
  cancelForm: FormGroup;
  loading = false;
  submitted = false;
  submittedCancel = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  statusList: any[];

  idRequest = 0;

  constructor(private formBuilder: FormBuilder, private budgetRequestService: BudgetRequestService, private modalService: ModalDefaultService) { }

  ngOnInit() {

    this.getStatus();

    this.searchForm = this.formBuilder.group({ customer: [''], attendancePlace: [''], technician: [''], status: [5], fromDate: [''], toDate: [''] });

    this.get(this.page, this.searchForm.controls.customer.value, this.searchForm.controls.attendancePlace.value, this.searchForm.controls.technician.value,
      this.searchForm.controls.status.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.customer.value, this.searchForm.controls.attendancePlace.value, this.searchForm.controls.technician.value,
      this.searchForm.controls.status.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.customer.value, this.searchForm.controls.attendancePlace.value, this.searchForm.controls.technician.value,
      this.searchForm.controls.status.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value);
  }

  private get(page: number, customer: string, attendancePlace: string, technician: string, status: number, fromDate: Date, toDate: Date) {
    this.submitted = true;

    this.loading = true;
    this.budgetRequestService.getBudgetRequests(page, customer, attendancePlace, technician, status, fromDate, toDate)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.budgetRequests = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  private getStatus() {
    this.budgetRequestService.getStatus()
      .subscribe(
        data => {
          this.statusList = data;
        },
        error => {
          this.error = error;
        });
  }

  showFinalizeRequest(content, id) {

    this.idRequest = id;
    this.modalService.openDefault(content, "lg");
  }

  finalizeRequest(id: number) {

    this.budgetRequestService.finalizeRequest(id)
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.idRequest = 0;
          this.ngOnInit();
        },
        error => {
          this.modalService.closeDefault();
          this.idRequest = 0;
          this.error = error;
        });
  }

  showCancelRequest(content, id) {

    this.cancelForm = this.formBuilder.group({ cancelObservation: ['', Validators.required] });

    this.idRequest = id;
    this.modalService.openDefault(content, "lg");
  }

  cancelRequest(id: number) {

    this.submittedCancel = true;

    if (this.cancelForm.invalid) {
      return;
    }

    this.budgetRequestService.cancelRequest(id, this.cancelForm.controls.cancelObservation.value)
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.idRequest = 0;
          this.ngOnInit();
        },
        error => {
          this.modalService.closeDefault();
          this.idRequest = 0;
          this.error = error;
        });
  }

  downloadRequest(id, requestNumber) {

    if (!id) {
      return;
    }

    this.budgetRequestService.exportRequest(id)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'solicitacao-de-orcamento-' + requestNumber + '.pdf');
        });
  }
}
