import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-authorization-login',
  templateUrl: './authorization-login.component.html',
  styleUrls: ['./authorization-login.component.css']
})
export class AuthorizationLoginComponent implements OnInit {
  editForm: FormGroup;
  loading = false;
  submitted = false;
  idUser = 0;
  token: string;
  authorization: boolean;

  message: any = null;

  constructor(private activeRoute: ActivatedRoute, private router: Router, private userService: UserService) { }

  ngOnInit() {

    this.activeRoute.params.subscribe(routeParams => {
      this.idUser = parseInt(routeParams.id);
      this.token = routeParams.token;
      this.authorization = routeParams.authorization == 1;
    });

    this.userService.setAuthorizationToLogin(this.idUser, this.token, this.authorization, false)
      .subscribe(
        data => {
          this.message = data;
        },
        error => {
          this.loading = false;
          this.router.navigate(['login']);
        });
  }
}
