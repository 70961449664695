import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AttendanceService } from '../_services/attendance.service';
import { ModalDefaultService } from '../modal-default/modal-default.service';
import { EquipmentService } from '../_services/equipment.service';
import { EmployeeService } from '../_services/employee.service';
import { Employee } from '../_models/employee';

@Component({
    selector: 'app-code-reader',
    templateUrl: './code-reader.component.html',
    styleUrls: ['./code-reader.component.css']
})
export class CodeReaderComponent implements OnInit {
  loading = false;
  submitted = false;
  scannerEnabled = true;
  qrResultString: string;
  rootUrl: string;
  redirectionCode: number;
  attendanceSituation;
  idEquipment = 0;
  openTypeCodeInput = false;
  date = new Date();
  employeesList: Employee[];
  error = '';

  year = 0;
  month = 0;

  dateForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private equipmentService: EquipmentService, private attendanceService: AttendanceService, private modalService: ModalDefaultService,
    private activeRoute: ActivatedRoute, private employeeService: EmployeeService, private router: Router) { }

    ngOnInit() {
        this.activeRoute.params.subscribe(routeParams => {
            this.redirectionCode = routeParams.redirectionCode;
        });

      this.year = this.date.getFullYear();
      this.month = this.date.getMonth() + 1;

      this.rootUrl = '';

      this.getEmployees();
    }

  validateResult(resultString, content) {
    this.scannerEnabled = false;

    if (resultString.includes(this.rootUrl)) {
      this.qrResultString = resultString.replace(this.rootUrl + '/', '');
      const qrCode = parseInt(this.qrResultString);

      if (this.redirectionCode == -1) {
        this.router.navigate(['equipmentResume?qrCode=' + qrCode]);
      }
      else {
        this.equipmentService.getEquipmentByQRCode(qrCode)
          .subscribe(
            data => {
              if (data != null) {
                this.showModal(content, data.id);
              }
              else {
                alert("Código inválido ou não encontrado!");
              }

              this.loading = false;
              this.submitted = false;
              this.scannerEnabled = true;
            },
            error => {
              this.loading = false;
              this.submitted = false;
              this.scannerEnabled = true;
            });
      }
    }
    else {
      alert('O código lido é inválido');
      this.scannerEnabled = true;
    }
  }

  showModal(content, idEquipment) {
    this.loading = true;
    this.submitted = true;

    this.dateForm = this.formBuilder.group({
      startDate: [null], employee: [null]
    });

    this.idEquipment = idEquipment;

    this.attendanceService.getSituation(idEquipment, this.year, this.month)
      .subscribe(
        data => {
          if (data != null) {
            this.attendanceSituation = data;

            this.modalService.openDefault(content, null).result.then(
              (closeResult) => {
                if (closeResult == "send")
                  console.log("modal closed : ", closeResult);
              }, (dismissReason) => {
                //modal Dismiss
                console.log("modal dismissed when used pressed ESC button");
              })
          }

          this.loading = false;
          this.submitted = false;
          this.scannerEnabled = true;
        },
        error => {
          this.loading = false;
          this.submitted = false;
          this.scannerEnabled = true;
        });
  }

  closeModal() {
    this.scannerEnabled = true;
    this.modalService.closeDefault();
  }

  notAuthorizedAttendance() {
    const date = new Date();

    this.setAttendance("attendances/equipment/" + this.idEquipment + "/year/" + date.getFullYear() + "/month/" + date.getMonth() + 1 + "/notAuthorized", true);
  }

  setAttendance(endpoint, isPostAction) {

    this.submitted = true;
    this.loading = true;

    const date = new Date();

    const startDate = this.dateForm.controls.startDate.value ? new Date(this.dateForm.controls.startDate.value) : null;
    var idEmployee = this.dateForm.controls.employee.value ? this.dateForm.controls.employee.value.id : null;

    if (startDate) {
      startDate.setTime(date.getTime());
    }

    this.attendanceService.executeAction(endpoint, isPostAction, startDate, idEmployee)
      .subscribe(
        data => {
          this.modalService.closeDefault();

          if (!data) {
            this.loading = false;
            this.submitted = false;
          }
          else {
            if (data.status == 3) {
              this.router.navigate(['attendance/signature/' + data.id]);
            }
            else
              if (data.status == 4) {
                this.router.navigate(['attendance/list']);
              }
              else {
                this.router.navigate(['attendance/checklistCompletion/' + data.id]);
              }
          }
        },
        error => {
          this.loading = false;
          this.submitted = false;
        });
  }

  private getEmployees() {
    this.employeeService.getEmployeeList()
      .subscribe(
        data => {
          this.employeesList = data;
        },
        error => {
          this.error = error;
        });
  }

}
