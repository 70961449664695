import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../app/_services/authentication.service';
import { ModalDefaultService } from '../modal-default/modal-default.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  releaseForm: FormGroup;
  loading = false;
  submitted = false;
  submittedForgotPassword = false;
  returnUrl: string;

  authorized: boolean = null;
  intervalTimer = null;
  isTechnician = false;

  idUser = null;
  authorizationToken = null;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService,
    private userService: UserService, private modalService: ModalDefaultService) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({ username: ['', Validators.required], password: ['', Validators.required] });
    this.forgotPasswordForm = this.formBuilder.group({ usernameForgotPassword: ['', Validators.required] });
    this.releaseForm = this.formBuilder.group({ usernameStaff: ['', Validators.required], passwordStaff: ['', Validators.required] });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit(contentRelease) {

    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.name != null) {
            this.router.navigate([this.returnUrl]);
          }
          else {
            this.isTechnician = true;
            this.idUser = data.id;
            this.authorizationToken = data.authorizationToken;
            this.intervalTimer = setInterval(() => this.checkAuthorization(data.id, data.authorizationToken), 10000);
          }
        },
        error => {
          this.loading = false;
          this.submitted = false;
        });
  }

  onSubmitForgotPassword() {
    this.submittedForgotPassword = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.sendRecoveryEmail(this.forgotPasswordForm.controls.usernameForgotPassword.value)
      .pipe(first())
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.loading = false;
        },
        error => {
          this.loading = false;
        });

  }

  showModal(content) {
    this.modalService.openDefault(content, null).result.then(
      (closeResult) => {
        if (closeResult == "send")
          console.log("modal closed : ", closeResult);
      }, (dismissReason) => {
        //modal Dismiss
        console.log("modal dismissed when used pressed ESC button");
      })
  }


  showReleaseModal(contentRelease) {
    this.modalService.openDefault(contentRelease, null).result.then(
      (closeResult) => {
        this.loading = false;
        this.submitted = false;
      }, (dismissReason) => {
        this.loading = false;
        this.submitted = false;
      })
  }


  onSubmitRelease() {

    this.submitted = true;

    if (this.loginForm.invalid || this.releaseForm.invalid) {
      return;
    }

    this.loading = true;

    /*
    this.authenticationService.loginTechnician(this.loginForm.controls.username.value, this.loginForm.controls.password.value,
      this.releaseForm.controls.usernameStaff.value, this.releaseForm.controls.passwordStaff.value)
      .pipe(first())
      .subscribe(
        data => {
          this.modalService.closeDefault();
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.loading = false;
          this.submitted = false;
        });
    */
  }

  cancelLogin() {
    this.userService.setAuthorizationToLogin(this.idUser, this.authorizationToken, false, true)
      .subscribe(
        data => {
          location.reload();
        },
        error => {
          location.reload();
        });
  }

  checkAuthorization(idUser: number, authorizationToken: string) {
    this.userService.checkAuthorization(idUser, authorizationToken)
      .subscribe(
        dataCheck => {
          this.authorized = dataCheck;

          if (dataCheck != null) {

            if (this.loginForm.invalid) {
              return;
            }

            this.authenticationService.loginTechnician(this.loginForm.controls.username.value, this.loginForm.controls.password.value, this.authorized)
              .pipe(first())
              .subscribe(
                data => {
                  clearInterval(this.intervalTimer);
                  this.router.navigate([this.returnUrl]);
                },
                error => {
                  clearInterval(this.intervalTimer);
                  this.loading = false;
                  this.submitted = false;
                });
          }
        },
        error => {
          clearInterval(this.intervalTimer);
          this.loading = false;
          this.submitted = false;
        });
  }
}
