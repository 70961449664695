import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { map } from 'rxjs/operators';
import { PaginatedResponse } from '../_models/paginated-response';
import { Brand } from '../_models/brand';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private repo: RepositoryService) { }

  getBrands(page: number, argument: string) {
    let urlParams = '?page=' + page;

    if (argument) {
      urlParams += '&argument=' + argument;
    }

    return this.repo.getData('brands' + urlParams)
      .pipe(map((paginatedResponse: PaginatedResponse<Brand>) => {
        return paginatedResponse;
      }));
  }

  getBrand(id: number) {
    return this.repo.getData('brands/' + id)
      .pipe(map((brand: Brand) => {
        return brand;
      }));
  }

  getBrandList() {
    return this.repo.getData('brands/all')
      .pipe(map((brands: Brand[]) => {
        return brands;
      }));
  }

  createOrUpdate(id: number, brand: Brand) {
    if (id && id > 0) {
      return this.repo.put('brands/' + id, brand);
    }
    else {
      brand.id = null;
      return this.repo.post('brands', brand);
    }
  }
}
