import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AttendancePlace } from '../../_models/attendance-place';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { CalledService } from '../../_services/called.service';
import { ReportService } from '../../_services/report.service';

@Component({
  selector: 'app-called-per-place',
  templateUrl: './called-per-place.component.html',
  styleUrls: ['./called-per-place.component.css']
})
export class CalledPerPlaceComponent implements OnInit {
  calleds: any[];
  searchForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  page = 1;
  totalResults = 0;
  resultsPerPage = 0;
  loadingExport = false;
  statusList: any[];

  formatter = (place: AttendancePlace) => place.name;

  constructor(private formBuilder: FormBuilder, private activeRoute: ActivatedRoute, private router: Router, private reportService: ReportService, private attendancePlaceService: AttendancePlaceService, private calledService: CalledService) { }

  ngOnInit() {

    this.getStatus();

    this.searchForm = this.formBuilder.group({ idAttendancePlace: ['', Validators.required], fromDate: [null], toDate: [null], status: [''] });

    this.get(this.page, this.searchForm.controls.idAttendancePlace.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value);
  }

  onSelectedPage() {
    this.get(this.page, this.searchForm.controls.idAttendancePlace.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value);
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.page = 1;
    this.get(this.page, this.searchForm.controls.idAttendancePlace.value, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value, this.searchForm.controls.status.value);
  }

  private get(page: number, attendancePlace: any, fromDate: Date, toDate: Date, status: number) {
    this.submitted = true;

    if (this.searchForm.invalid) {
      return;
    }

    this.loading = true;

    var idAttendancePlace = attendancePlace != null ? attendancePlace.id : null;

    this.reportService.getCalledPerPlace(page, idAttendancePlace, fromDate, toDate, status)
      .subscribe(
        data => {
          this.page = data.pageNumber;
          this.resultsPerPage = data.resultsPerPage;
          this.totalResults = data.totalResults;
          this.calleds = data.response;
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  getAttendancePlaces = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.attendancePlaceService.getByArgument(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  private getStatus() {
    this.calledService.getStatus()
      .subscribe(
        data => {
          this.statusList = data;
        },
        error => {
          this.error = error;
        });
  }

  exportCalledPerPlace() {

    if (!this.searchForm.controls.idAttendancePlace.value) {
      return;
    }

    this.reportService.exportCalledPerPlace(this.searchForm.controls.idAttendancePlace.value.id, this.searchForm.controls.fromDate.value, this.searchForm.controls.toDate.value)
      .subscribe(
        data => {
          FileSaver.saveAs(data, 'relatorio-chamado-por-local.pdf');
        });
  }
}
