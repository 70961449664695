import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerService } from '../../_services/customer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from '../../_models/customer';
import { AttendancePlace } from '../../_models/attendance-place';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { Address } from '../../_models/address';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.css']
})
export class CustomerDetailComponent implements OnInit {
    detailForm: FormGroup;
    idCustomer = 0;
    editMode = false;
    loading = false;
    submitted = false;
    error = '';
    typesList = [];
    attendancePlaces: AttendancePlace[];
    idAddress = null;

    constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private customerService: CustomerService, private attendancePlaceService: AttendancePlaceService) { }

    ngOnInit() {

      this.detailForm = this.formBuilder.group({
        cpfCnpj: ['', Validators.required], idExternal: [''], corporateName: ['', Validators.required], businessName: [''], personType: [null, Validators.required],
        zipCode: [''], stateName: [''], cityName: [''], streetName: ['']
      });

        this.activeRoute.params.subscribe(routeParams => {
            this.idCustomer = parseInt(routeParams.id);
        });

        this.getTypes();
        this.getAttendancePlaces();

        if (this.idCustomer > 0) {

              this.detailForm.disable();
          
              this.customerService.getCustomer(this.idCustomer)
                  .subscribe(
                      data => {
                      this.detailForm.setValue({
                        cpfCnpj: data.cpfCnpj, idExternal: data.idExternal, corporateName: data.corporateName, businessName: data.businessName, personType: data.personType,
                        zipCode: data.address != null ? data.address.zipCode : null, stateName: data.address != null ? data.address.state : null, cityName: data.address != null ? data.address.city : null,
                        streetName: data.address != null ? data.address.description : null
                      });

                      if (data.address != null) {
                        this.idAddress = data.address.id;
                      }

                      },
                      error => {
                          this.router.navigate(['customer']);
                      });
        }
        else {
            this.setEditMode();
        }
    }
  
    onSubmit() {
      
        this.submitted = true;

        if (((this.detailForm.controls.stateName.value || this.idAddress) && (!this.detailForm.controls.cityName.value || !this.detailForm.controls.streetName.value)) ||
           ((this.detailForm.controls.cityName.value || this.idAddress) && (!this.detailForm.controls.stateName.value || !this.detailForm.controls.streetName.value)) ||
           ((this.detailForm.controls.streetName.value || this.idAddress) && (!this.detailForm.controls.cityName.value || !this.detailForm.controls.stateName.value))) {
            return;
        }

        if (this.detailForm.invalid) {
            return;
        }

        this.loading = true;
      
        var newCustomer = new Customer();
        newCustomer.id = this.idCustomer;
        newCustomer.businessName = this.detailForm.controls.businessName.value;
        newCustomer.corporateName = this.detailForm.controls.corporateName.value;
        newCustomer.cpfCnpj = this.detailForm.controls.cpfCnpj.value;
        newCustomer.idExternal = parseInt(this.detailForm.controls.idExternal.value);
        newCustomer.personType = this.detailForm.controls.personType.value;
        newCustomer.address = null;

        if (this.detailForm.controls.stateName.value && this.detailForm.controls.cityName.value && this.detailForm.controls.streetName.value) {
            var address = new Address();
            address.id = this.idAddress;
            address.state = this.detailForm.controls.stateName.value;
            address.city = this.detailForm.controls.cityName.value;
            address.description = this.detailForm.controls.streetName.value;
            address.zipCode = this.detailForm.controls.zipCode.value;

            newCustomer.address = address;
        }

        this.customerService.createOrUpdate(this.idCustomer, newCustomer)
            .subscribe(
                data => {
                    this.router.navigate(['customer/list']);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
  
    setEditMode() {
        this.editMode = true;
        this.detailForm.enable();
    }
  
    private getTypes() {
        this.customerService.getCustomerTypes()
            .subscribe(
                data => {
                    this.typesList = data;
                },
                error => {
                    this.error = error;
                });
    }

    private getAttendancePlaces() {
        this.attendancePlaceService.getAttendancePlacesByCustomer(this.idCustomer)
            .subscribe(
                data => {
                  this.attendancePlaces = data;
                },
                error => {
                  this.error = error;
                });
    }
  
    cpfCnpjMask(): string {
        if (this.detailForm.controls.cpfCnpj.value) {
          return this.detailForm.controls.cpfCnpj.value.length < 12 ? '000.000.000-009' : '00.000.000/0000-00';
        }
    }
  
    zipCodeMask(): string {
        if (this.detailForm.controls.zipCode.value) {
          return '00000-000';
        }
    }
}
