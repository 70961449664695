import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReasonRequest } from '../../_models/reason-request';
import { ReasonRequestService } from '../../_services/reason-request.service';

@Component({
  selector: 'app-reason-request-detail',
  templateUrl: './reason-request-detail.component.html',
  styleUrls: ['./reason-request-detail.component.css']
})
export class ReasonRequestDetailComponent implements OnInit {

  detailForm: FormGroup;
  idReasonRequest = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private reasonRequestService: ReasonRequestService) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({ reasonRequestName: ['', Validators.required] });

    this.activeRoute.params.subscribe(routeParams => {
      this.idReasonRequest = parseInt(routeParams.id);
    });

    if (this.idReasonRequest > 0) {

      this.detailForm.disable();

      this.reasonRequestService.getReasonRequest(this.idReasonRequest)
        .subscribe(
          data => {
            this.detailForm.setValue({
              reasonRequestName: data.name
            });
          },
          error => {
            this.router.navigate(['route']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newReasonRequest = new ReasonRequest();
    newReasonRequest.id = this.idReasonRequest;
    newReasonRequest.name = this.detailForm.controls.reasonRequestName.value;

    this.reasonRequestService.createOrUpdate(this.idReasonRequest, newReasonRequest)
      .subscribe(
        data => {
          this.router.navigate(['reasonRequest/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
