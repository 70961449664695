import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AttendancePlace } from '../../_models/attendance-place';
import { BudgetRequest } from '../../_models/budget-request';
import { AttendancePlaceService } from '../../_services/attendance-place.service';
import { BudgetRequestService } from '../../_services/budget-request.service';
import { EquipmentService } from '../../_services/equipment.service';
import { ReasonRequestService } from '../../_services/reason-request.service';

@Component({
  selector: 'app-budget-request-details',
  templateUrl: './budget-request-details.component.html',
  styleUrls: ['./budget-request-details.component.css']
})
export class BudgetRequestDetailsComponent implements OnInit {
  detailForm: FormGroup;
  editItemForm: FormGroup;

  editMode = false;
  loading = false;
  submitted = false;
  error = '';

  submittedItem = false;
  loadingItem = false;

  idBudgetRequest = 0;

  equipmentList = [];
  reasonRequestList = [];

  budgetRequestItems = null;
  inactiveContract = false;

  inputFile = null;
  accept = '*';
  myFormData: FormData = new FormData();

  status = null;

  formatter = (attendancePlace: AttendancePlace) => attendancePlace.name;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private equipmentService: EquipmentService, private attendancePlaceService: AttendancePlaceService,
    private budgetRequestService: BudgetRequestService, private reasonRequestService: ReasonRequestService) { }

  ngOnInit() {

    this.budgetRequestItems = null;

    this.getReasonRequests();

    this.detailForm = this.formBuilder.group({
      attendancePlace: [null, Validators.required], contract: [''], idEquipment: [null, Validators.required], idReasonRequest: [null, Validators.required], observation: [null], requestNumber: [null],
      equipmentBrand: [null], equipmentModel: [null], equipmentCode: [null], cancelationObservation: [null], urgent: [false], urgentReason: [null]
    });

    this.editItemForm = this.formBuilder.group({
      description: [null, Validators.required]
    });

    if (this.idBudgetRequest == null || this.idBudgetRequest == 0) {
      this.activeRoute.params.subscribe(routeParams => {
        this.idBudgetRequest = parseInt(routeParams.id);
      });
    }

    if (this.idBudgetRequest > 0) {

      this.detailForm.disable();

      this.budgetRequestService.getBudgetRequest(this.idBudgetRequest)
        .subscribe(
          data => {

            this.detailForm.setValue({
              attendancePlace: data.attendancePlace, contract: data.attendancePlace ? data.attendancePlace.contract.contractNumber : "", idEquipment: data.equipment.id, idReasonRequest: data.reasonRequest.id,
              observation: data.observation, requestNumber: data.requestNumber, equipmentBrand: data.equipment.brand != null ? data.equipment.brand.name : "", equipmentModel: data.equipment.model,
              equipmentCode: data.equipment.qrCode, cancelationObservation: data.cancelationObservation, urgent: data.urgent, urgentReason: data.urgentReason,
            });

            this.status = data.status;

            if (data.attendancePlace) {
              this.getEquipments(data.attendancePlace.id);
            }

            if (data.items != null && data.items.length > 0) {
              this.budgetRequestItems = data.items;
            }
            else {
              this.budgetRequestItems = null;
            }
          },
          error => {
            this.router.navigate(['budgetRequest']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.controls.urgent.value && !this.detailForm.controls.urgentReason.value) {
      return;
    }

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    var newBudgetRequest = new BudgetRequest();
    newBudgetRequest.id = this.idBudgetRequest;
    newBudgetRequest.idAttendancePlace = this.detailForm.controls.attendancePlace.value.id;
    newBudgetRequest.idEquipment = this.detailForm.controls.idEquipment.value;
    newBudgetRequest.idReasonRequest = this.detailForm.controls.idReasonRequest.value;
    newBudgetRequest.observation = this.detailForm.controls.observation.value;
    newBudgetRequest.urgent = this.detailForm.controls.urgent.value;
    newBudgetRequest.urgentReason = this.detailForm.controls.urgentReason.value;

    this.budgetRequestService.createOrUpdate(this.idBudgetRequest, newBudgetRequest)
      .subscribe(
        data => {
          if (data) {
            this.idBudgetRequest = data.id;
            this.detailForm.controls.requestNumber.setValue(data.requestNumber);
            this.submitted = false;
            this.loading = false;
          }
          else {
            this.router.navigate(['budgetRequest/list']);
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  onSubmitItem() {

    this.submittedItem = true;

    if (this.editItemForm.invalid) {
      return;
    }

    this.loadingItem = true;

    this.myFormData = new FormData();
    this.myFormData.append("description", this.editItemForm.controls.description.value);

    if (this.inputFile) {
      this.myFormData.append("file", this.inputFile, this.inputFile.name);
    }

    this.budgetRequestService.createItem(this.idBudgetRequest, this.myFormData)
      .subscribe(
        result => {

          this.loadingItem = false;
          this.submittedItem = false;
          this.inputFile = null;

          this.ngOnInit();
        },
        error => {
          this.loadingItem = false;
          this.submittedItem = false;
          this.inputFile = null;
        });
  }

  deleteFile() {
    this.inputFile = null;
  }

  deleteItem(id) {
    this.budgetRequestService.deleteItem(id)
      .subscribe(
        result => {
          this.ngOnInit();
        },
        error => {

        });
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  searchAttendancePlaces = (text$: Observable<any>) => text$.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    filter(term => term.length >= 3),
    switchMap(term =>
      this.attendancePlaceService.getByAnyArgument(term).pipe(
        catchError(() => {
          return of([]);
        }))
    )
  );

  selectedAttendancePlace() {

    this.inactiveContract = false;

    if (this.detailForm.controls.attendancePlace.value) {
      this.attendancePlaceService.getAttendancePlace(this.detailForm.controls.attendancePlace.value.id)
        .subscribe(
          data => {

            this.detailForm.controls.contract.setValue(data.contract.contractNumber);

            if (data.contract.status == 1) {
              this.detailForm.controls.attendancePlace.setValue(data);
              this.getEquipments(data.id);
            }
            else {
              this.inactiveContract = true;
              this.equipmentList = [];
            }
          },
          error => {
            this.router.navigate(['budgetRequest/list']);
          });
    }
    else {
      this.detailForm.controls.contract.setValue("");
      this.detailForm.controls.idEquipment.setValue(null);
    }
  }

  private getEquipments(idAttendancePlace) {

    this.equipmentService.getByAttendancePlace(idAttendancePlace)
      .subscribe(
        data => {
          this.equipmentList = data;
        },
        error => {
          this.router.navigate(['budgetRequest/list']);
        });
  }

  getEquipment() {

    if (!this.detailForm.controls.idEquipment.value) {
      this.detailForm.controls.equipmentBrand.setValue("");
      this.detailForm.controls.equipmentModel.setValue("");
      this.detailForm.controls.equipmentCode.setValue("");
      return;
    }

    this.equipmentService.getEquipment(this.detailForm.controls.idEquipment.value)
      .subscribe(
        data => {
          if (data) {
            this.detailForm.controls.equipmentBrand.setValue(data.brand != null ? data.brand.name : null);
            this.detailForm.controls.equipmentModel.setValue(data.model);
            this.detailForm.controls.equipmentCode.setValue(data.qrCode);
          }
          else {
            this.detailForm.controls.equipmentBrand.setValue("");
            this.detailForm.controls.equipmentModel.setValue("");
            this.detailForm.controls.equipmentCode.setValue("");
          }
        },
        error => {
          this.router.navigate(['budgetRequest/list']);
        });
  }

  private getReasonRequests() {

    this.reasonRequestService.getReasonRequestList()
      .subscribe(
        data => {
          this.reasonRequestList = data;
        },
        error => {
          this.router.navigate(['budgetRequest/list']);
        });
  }

  downloadFile(id, idFile, item) {
    this.budgetRequestService.downloadFile(id, idFile)
      .subscribe(
        data => {
          FileSaver.saveAs(data, item.name);
        });
  }
}
