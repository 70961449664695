import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Solution } from '../../_models/solution';
import { SolutionService } from '../../_services/solution.service';

@Component({
  selector: 'app-solution-detail',
  templateUrl: './solution-detail.component.html',
  styleUrls: ['./solution-detail.component.css']
})
export class SolutionDetailComponent implements OnInit {

  detailForm: FormGroup;
  idSolution = 0;
  editMode = false;
  loading = false;
  submitted = false;
  error = '';

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private solutionService: SolutionService) { }

  ngOnInit() {

    this.detailForm = this.formBuilder.group({ solutionName: ['', Validators.required] });

    this.activeRoute.params.subscribe(routeParams => {
      this.idSolution = parseInt(routeParams.id);
    });

    if (this.idSolution > 0) {

      this.detailForm.disable();

      this.solutionService.getSolution(this.idSolution)
        .subscribe(
          data => {
            this.detailForm.setValue({
              solutionName: data.name
            });
          },
          error => {
            this.router.navigate(['route']);
          });
    }
    else {
      this.setEditMode();
    }
  }

  setEditMode() {
    this.editMode = true;
    this.detailForm.enable();
  }

  onSubmit() {

    this.submitted = true;

    if (this.detailForm.invalid) {
      return;
    }

    this.loading = true;

    const newSolution = new Solution();
    newSolution.id = this.idSolution;
    newSolution.name = this.detailForm.controls.solutionName.value;

    this.solutionService.createOrUpdate(this.idSolution, newSolution)
      .subscribe(
        data => {
          this.router.navigate(['solution/list']);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
