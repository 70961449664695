import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeService } from '../../_services/employee.service';
import { Employee } from '../../_models/employee';
import { UserService } from '../../_services/user.service';
import { User } from '../../_models/user';
import { RouteService } from '../../_services/route.service';
import { RouteModel } from '../../_models/route-model';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {
    detailForm: FormGroup;
    idEmployee = 0;
    editMode = false;
    loading = false;
    submitted = false;
    error = '';
    userList: User[];
    routes: RouteModel[];

    constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private employeeService: EmployeeService, private userService: UserService, private routeService: RouteService) { }

    ngOnInit() {
        this.detailForm = this.formBuilder.group({ cpf: ['', Validators.required], idExternal: [''], employeeName: ['', Validators.required], user: [null] });

        this.activeRoute.params.subscribe(routeParams => {
          this.idEmployee = parseInt(routeParams.id);
        });

        this.getUsers();
        this.getRoutes();

        if (this.idEmployee > 0) {

            this.detailForm.disable();

            this.employeeService.getEmployee(this.idEmployee)
              .subscribe(
                data => {
                  this.detailForm.setValue({ cpf: data.cpf, idExternal: data.idExternal, employeeName: data.name, user: data.user });
                },
                error => {
                  this.router.navigate(['employee']);
                });
        }
        else {
            this.setEditMode();
        }
    }
  
    onSubmit() {
      
        this.submitted = true;
      
        if (this.detailForm.invalid) {
            return;
        }

        this.loading = true;
      
        const newEmployee = new Employee();
        newEmployee.id = this.idEmployee;
        newEmployee.cpf = this.detailForm.controls.cpf.value;
        newEmployee.idExternal = parseInt(this.detailForm.controls.idExternal.value);
        newEmployee.name = this.detailForm.controls.employeeName.value;
        newEmployee.user = this.detailForm.controls.user.value;

        this.employeeService.createOrUpdate(this.idEmployee, newEmployee)
            .subscribe(
                data => {
                    this.router.navigate(['employee/list']);
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
  
    setEditMode() {
        this.editMode = true;
        this.detailForm.enable();
    }
  
    private getUsers() {
        this.userService.getUsersActive()
            .subscribe(
                data => {
                    this.userList = data;
                },
                error => {
                    this.error = error;
                });
    }
  
    private getRoutes() {
        this.routeService.getRoutesByEmployee(this.idEmployee)
            .subscribe(
                data => {
                  this.routes = data;
                },
                error => {
                  this.error = error;
                });
    }
}
