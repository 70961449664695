import { Injectable } from '@angular/core';
import { RepositoryService } from './repository.service';
import { PaginatedResponse } from '../_models/paginated-response';
import { Contract } from '../_models/contract';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

    constructor(private repo: RepositoryService) { }

    getContracts(page: number, contractNumber: string, customerArgument: string) {
        let urlParams = '?page=' + page;

        if (customerArgument) {
            urlParams += '&argument=' + customerArgument;
        }

        if (contractNumber) {
            urlParams += '&contract=' + contractNumber;
        }

        return this.repo.getData('contracts' + urlParams)
            .pipe(map((paginatedResponse: PaginatedResponse<Contract>) => {
                return paginatedResponse;
            }));
    }

    getContract(id: number) {
        return this.repo.getData('contracts/' + id)
            .pipe(map((contract: Contract) => {
                return contract;
            }));
    }
  
    createOrUpdate(id: number, contract: Contract) {
        if (id && id > 0) {
          return this.repo.put('contracts/' + id, contract);
        }
        else {
          contract.id = null;
          return this.repo.post('contracts', contract);
        }
    }

    getAttendanceFactors() {
      return this.repo.getData('contracts/attendanceFactors')
            .pipe(map((attendanceFactors: any[]) => {
                return attendanceFactors;
            }));
    }

    getContractsActives() {
      return this.repo.getData('contracts/actives')
        .pipe(map((routes: Contract[]) => {
          return routes;
        }));
    }

    getContractsByCustomer(idCustomer: number) {
      return this.repo.getData('contracts/customer/' + idCustomer)
        .pipe(map((routes: Contract[]) => {
          return routes;
        }));
    }
  
    getContractStatus() {
      return this.repo.getData('contracts/status')
            .pipe(map((statusList: any[]) => {
                return statusList;
            }));
    }
}
