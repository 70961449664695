import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';
import { ToastService } from '../toast-global/toast.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private toastService: ToastService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && (event.status === 200 || event.status == 201) && event.body && event.body.resultMessage) {
                    this.toastService.show(event.body.resultMessage, { classname: 'bg-success text-light' });
                }
            }),
            catchError(err => {
                let currentUser = this.authenticationService.currentUserValue;
                if (err.status === 401 && currentUser) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    location.reload(true);
                }

                const error = err.error.title || err.statusText;

                this.toastService.show(error, { classname: 'bg-danger text-light' });

                return throwError(error);
            }))
    }
}
